export default {
  name: "OrdersComponent",
  data() {
    return {
      heightTable: 0,
      bHeightTable: 0,
      texts: "",
      tableDataInProcces: [],
      tableDataFinish: [],
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      iTotalItems: 0,
      screenHeight: 0,
      arr: {},
      showTable: false,
      images: [],
      activeTab: 1,
      tab: null,
      iFilter: 1,
    };
  },
  beforeMount() {
    // vacia todos los filtros al momento de entrar
    this.$store.commit("setMenuSidebar", {active:false});
    this.$store.commit("setOrderStatus", []);
    this.$store.commit("setPaymentStatus", null);
    this.$store.commit("setStartDate", null);
    this.$store.commit("setEndDate", null);

    this.texts = FILE.ordersTexts[this.selectLanguage];
    this.fillTable();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    // calcula el height para agregarlo en el empty data
    handleResize() {
      this.screenHeight = window.innerHeight - 400;
    },

    // llena el arreglo tableDataInProcces para mostrarlo en la tabla principal
    fillTable() {
      DB.get(`${URI}/api/v1/${this.selectLanguage}/sales-orders`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          tStart: this.startDate === null ? "" : this.startDate,
          tEnd: this.endDate === null ? "" : this.endDate,
          aSalesOrderStatus: this.orderStatus,
          bPaid: this.paymentStatus,
          iFilter: this.iFilter,
        },
      })
        .then((response) => {
          var i = 0;
          this.tableDataInProcces = response.data.results.map((e) => {
            i++;
            return {
              ...e,
              dTotalPrice: this.mask(e.dTotalPrice),
              bPlatformAccess: e.bPlatformAccess ? "Activo" : "Inactivo",
            };
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;

          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    // manda al detalle de la orden seleccionada(item es el objeto de la oren seleccionada)
    detailItem(item) {
      this.$router.push(`/admin/order-detail/${item.sSalesOrderId}`);
    },
    // convierte en formato money(price es el precio que quieres formatear)
    mask(price) {
      const nf = new Intl.NumberFormat("en-US");
      let number = nf.format(price);
      return price % 1 === 0 ? (number = number + ".00") : number;
    },
    formatMillon(number) {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
  computed: {
    selectLanguage() {
      return this.$store.state.language;
    },
    // set search variable for filter option
    sSearch() {
      return this.$store.state.sSearch;
    },
    startDate() {
      return this.$store.state.dStartDate;
    },
    endDate() {
      return this.$store.state.dEndDate;
    },
    orderStatus: function() {
      return this.$store.state.aOrderStatus;
    },
    paymentStatus: function() {
      return this.$store.state.bPaymentStatus;
    },
    refreshTable() {
      return this.$store.state.refresh;
    },
  },
  watch: {
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.ordersTexts[this.selectLanguage];
        this.fillTable();
      }
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    endDate: function() {
      if (this.endDate) {
        this.iCurrentPage = 1;
        this.fillTable();
      }
    },
    startDate: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    orderStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    paymentStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    iPageNumber: function() {
      this.fillTable();
    },
    refreshTable: function() {
      this.fillTable();
    },
    iCurrentPage() {
      this.fillTable();
    },
    iFilter: function() {
      this.fillTable();
    },
    iItemsPerPage: function() {
      this.fillTable();
    },
    tab: function() {
      switch (this.tab) {
        case 0:
          this.tableDataInProcces = [];
          this.iCurrentPage = 1;
          this.iFilter = 1;
          break;
        case 1:
          this.tableDataInProcces = [];

          this.iCurrentPage = 1;
          this.iFilter = 2;
          break;
        case 2:
          this.tableDataInProcces = [];

          this.iCurrentPage = 1;
          this.iFilter = 2;
          break;

        default:
          break;
      }
    },
  },
};
