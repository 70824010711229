var texts = {
  en: {
    items: [
      { 
        id:'19feae76-e1df-4dc5-ba52-4a2ffbaba896',
        title: 'Orders', 
        routeName:['Orders','OrderDetail'],
        icon: "Icon-order-resp.svg",
        iconMDI:'mdi-home-outline',
        href: '/admin/orders',
        permission: '19feae76-e1df-4dc5-ba52-4a2ffbaba896',
      },
      { 
        id:'2334b4e0-ce21-41b1-ad22-0195bc13bea4',
        title: 'Disputes', 
        routeName:['Claims','ClaimsDetails'],
        icon: "Icon-claims-resp.svg",
        iconMDI:'mdi-alert-outline',
        href: '/admin/claims',
        permission: '2334b4e0-ce21-41b1-ad22-0195bc13bea4',
      },
      { 
        id:'257bc8cb-678f-4665-b239-9728735104ee',
        title: 'Customers', 
        routeName:['Customers','CustomersDetails'],
        icon: "Icon-customer-resp.svg",
        iconMDI:'mdi-basket-outline',
        href: '/admin/customers',
        permission: '257bc8cb-678f-4665-b239-9728735104ee',
      },
      { 
        id:'e5f49ea5-a5fd-4695-9d1f-2e10c7a1409c',
        title: 'Commissions', 
        routeName:['Commissions'],
        icon: "percent.svg",
        iconMDI:'mdi-percent-outline',
        href: '/admin/commissions',
        permission: 'e5f49ea5-a5fd-4695-9d1f-2e10c7a1409c',
      },
      { 
        id:'88bea0cf-71b9-4d09-a334-755d88f4192c',
        title: 'Vendors', 
        routeName:['Vendors','VendorsDetails'],
        icon: "Icon-user-resp.svg",
        iconMDI:'mdi-account-outline',
        href: '/admin/vendors',
        permission: '88bea0cf-71b9-4d09-a334-755d88f4192c',
      },
      { 
        id:'23c3b8c6-2d70-4494-9ae7-b88ece90c971',
        title: 'Category', 
        routeName:['Categories'],
        icon: "Icon-article-resp.svg",
        iconMDI:'mdi-shape-outline',
        href: '/admin/categories',
        permission: '23c3b8c6-2d70-4494-9ae7-b88ece90c971',
        children:[
          { 
            title: 'Subcategory', 
            routeName:['Subcategories'],
            icon: "Icon-subcategories-resp.svg",
            iconMDI:'mdi-border-all',
            href: '/admin/subcategories',
            permission: '23c3b8c6-2d70-4494-9ae7-b88ece90c971',
          },
        ]
      },
      { 
        id:'0ac1f663-3ca6-47cf-b01c-6942b868067d',
        title: 'Items', 
        routeName:['Articles','ArticleDetail'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-food-apple-outline',
        href: '/admin/articles',
        permission: '0ac1f663-3ca6-47cf-b01c-6942b868067d',
      },
      { 
        id:'null',
        title: 'Super Buyer', 
        routeName:['ShoppingCart'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-cart-outline',
        href: '/admin/shoppingCart',
        permission: 'null',
      },
      { 
        id:'null',
        title: 'Proplat Calculator', 
        routeName:['ProplatCalculator'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-calculator',
        href: '/admin/proplatCalculator',
        permission: 'null',
      },
      { 
        id:'null',
        title: 'Freight Prices', 
        routeName:['FreightPrices'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-airplane',
        href: '/admin/freightPrices',
        permission: 'null',
      },
      { 
        id:'null',
        title: 'Logistics', 
        routeName:['Logistic'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-truck-fast',
        href: '/admin/logistic',
        permission: 'null',
      },
      { 
        id:'null',
        title: 'Administrators', 
        routeName:['Subadmins'],
        icon: "Icon-subadmin-resp.svg",
        iconMDI:'mdi-shield-check-outline',
        href: '/admin/subadmins',
        permission: 'null',
      },
      { 
        id:'3d681ddc-160c-4e73-b3e1-0ef07840563a',
        title: 'My warehouses', 
        routeName:['Warehouse','WarehouseDetail'],
        icon: "archive.svg",
        iconMDI:'mdi-archive-outline',
        href: '/admin/warehouse',
        permission: '3d681ddc-160c-4e73-b3e1-0ef07840563a',
      },
    ],
    menu: {
      title1: 'Orders',
      title2: {
        title: 'Disputes',
        subTitle1: 'Open',
        subTitle2: 'Finished'
      },
      title3: 'Customers',
      title4: 'Suppliers',
      title5: 'Administrators',
      title6: 'Items',
      title7: 'Shopping cart',
      title8: 'Subcategory',
      title9: 'Category',
      closeSesion: 'Sign off',
      translation: {
        spanish:'Español',
        english:'English'
      },
      title10: 'Commissions',
      title11: 'My warehouses',
      title12: 'Freight Prices',
      title13: 'Proplat Calculator'
    },
  },
  sp: {
    items: [
      { 
        id:'19feae76-e1df-4dc5-ba52-4a2ffbaba896',
        title: 'Pedidos', 
        routeName:['Orders','OrderDetail'],
        icon: "Icon-order-resp.svg",
        iconMDI:'mdi-home-outline',
        href: '/admin/orders',
        permission: '19feae76-e1df-4dc5-ba52-4a2ffbaba896',
      },
      {
        id:'2334b4e0-ce21-41b1-ad22-0195bc13bea4',
        title: 'Disputas', 
        routeName:['Claims','ClaimsDetails'],
        icon: "Icon-claims-resp.svg",
        iconMDI:'mdi-alert-outline',
        href: '/admin/claims',
        permission: '2334b4e0-ce21-41b1-ad22-0195bc13bea4',
      },
      { 
        id:'257bc8cb-678f-4665-b239-9728735104ee',
        title: 'Compradores', 
        routeName:['Customers','CustomersDetails'],
        icon: "Icon-customer-resp.svg",
        iconMDI:'mdi-basket-outline',
        href: '/admin/customers',
        permission: '257bc8cb-678f-4665-b239-9728735104ee',
      },
      { 
        id:'e5f49ea5-a5fd-4695-9d1f-2e10c7a1409c',
        title: 'Comisiones', 
        routeName:['Commissions'],
        icon: "percent.svg",
        iconMDI:'mdi-percent-outline',
        href: '/admin/commissions',
        permission: 'e5f49ea5-a5fd-4695-9d1f-2e10c7a1409c',
      },
      { 
        id:'88bea0cf-71b9-4d09-a334-755d88f4192c',
        title: 'Proveedores', 
        routeName:['Vendors','VendorsDetails'],
        icon: "Icon-user-resp.svg",
        iconMDI:'mdi-account-outline',
        href: '/admin/vendors',
        permission: '88bea0cf-71b9-4d09-a334-755d88f4192c',
      },
      { 
        id:'23c3b8c6-2d70-4494-9ae7-b88ece90c971',
        title: 'Categorías', 
        routeName:['Categories'],
        icon: "Icon-article-resp.svg",
        iconMDI:'mdi-shape-outline',
        href: '/admin/categories',
        permission: '23c3b8c6-2d70-4494-9ae7-b88ece90c971',
        children:[
          { 
            title: 'Subcategorías', 
            routeName:['Subcategories'],
            icon: "Icon-subcategories-resp.svg",
            iconMDI:'mdi-border-all',
            href: '/admin/subcategories',
            permission: '23c3b8c6-2d70-4494-9ae7-b88ece90c971',
          },
        ]
      },
      { 
        id:'0ac1f663-3ca6-47cf-b01c-6942b868067d',
        title: 'Artículos', 
        routeName:['Articles','ArticleDetail'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-food-apple-outline',
        href: '/admin/articles',
        permission: '0ac1f663-3ca6-47cf-b01c-6942b868067d',
      },
      { 
        id:'null',
        title: 'Super comprador', 
        routeName:['ShoppingCart'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-cart-outline',
        href: '/admin/shoppingCart',
        permission: 'null',
      },
      { 
        id:'null',
        title: 'Proplat Calculator', 
        routeName:['ProplatCalculator'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-calculator',
        href: '/admin/proplatCalculator',
        permission: 'null',
      },
      { 
        id:'null',
        title: 'Tabulador de fletes', 
        routeName:['FreightPrices'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-airplane',
        href: '/admin/freightPrices',
        permission: 'null',
      },
      { 
        id:'null',
        title: 'Logistica', 
        routeName:['Logistic'],
        icon: "Icon-categories-resp.svg",
        iconMDI:'mdi-truck-fast',
        href: '/admin/logistic',
        permission: 'null',
      },
      { 
        id:'null',
        title: 'Administradores', 
        routeName:['Subadmins'],
        icon: "Icon-subadmin-resp.svg",
        iconMDI:'mdi-shield-check-outline',
        href: '/admin/subadmins',
        permission: 'null',
      },
      { 
        id:'3d681ddc-160c-4e73-b3e1-0ef07840563a',
        title: 'Mis almacenes', 
        routeName:['Warehouse','WarehouseDetail'],
        icon: "archive.svg",
        iconMDI:'mdi-archive-outline',
        href: '/admin/warehouse',
        permission: '3d681ddc-160c-4e73-b3e1-0ef07840563a',
      },
    ],
    menu: {
      title1: 'Pedidos',
      title2: {
        title: 'Reclamos',
        subTitle1: 'Abiertas',
        subTitle2: 'Finalizadas'
      },
      title3: 'Compradores',
      title4: 'Proveedores',
      title5: 'Administradores',
      title6: 'Artículos',
      title7: 'Super comprador',
      title8: 'Subcategorías',
      title9: 'Categorías',
      closeSesion: 'Cerrar sesión',
      translation: {
        spanish:'Español',
        english:'English'
      },
      title10: 'Comisiones',
      title11: 'Mis almacenes',
      title12: 'Tabulador de fletes',
      title13: 'Proplat Calculator',
    }
  }
};

export default texts;
