<template>
  <div>
    <div class="global-container-flex">
      <sidebar />
      <div :style="{ width: screenWidth + 'px' }" class="global-container-content-full-width">
        <navbar />
        <div class="v-container-search-bar" style="display: flex; flex-direction: column;">
          <div class="global-container-router-view" :style="{ height: screenHeight + 'px' }">
            <div class="v-container-search-bar mx-3 my-5">
              <div class="titleText" style="display: flex; align-items: center; flex-wrap: wrap;">
                <p class="mon-bold f-s45 l-spacing0 mb-0">
                  {{ "Logística" }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <div style="display: flex; flex-wrap: wrap; justify-content: flex-end;">
                <v-btn @click="openCreateFreight()" depressed class="gradient-button mt-3" color="transparent">
                  {{ "Crear flete" }}
                </v-btn>
              </div>
            </div>
            <v-tabs v-model="tab" background-color="transparent" color="#3D414A" show-arrows class="mx-3">
              <v-tab class="global-tab" v-for="item in texts.tabsItemsFreight" :key="item">
                {{ item }}
              </v-tab>
              <v-tabs-slider></v-tabs-slider>
            </v-tabs>

            <v-tabs-items class="global-item-tab mt-5 mx-3" v-model="tab">
              <v-tab-item>
                <empty-data v-if="items.length == 0" :screenHeight="screenHeight" />
                <div v-else style="padding-bottom: 30px;">
                  <v-row class="pa-3 py-5" style="background-color: white;">
                    <v-spacer></v-spacer>
                    <v-col cols="3">
                      <v-text-field
                        v-model="searchInTable"
                        class="pt-0 mt-0"
                        append-icon="mdi-magnify"
                        label="Buscar"
                        single-line
                        hide-details
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-data-table
                    :headers="headers"
                    :search="searchInTable"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :items="items"
                  >
                    <template v-slot:item.sVendorWarehouse="{ item }">
                      <v-progress-circular
                        class="my-2"
                        :rotate="360"
                        :size="50"
                        :width="7"
                        :value="40"
                        color="yellow"
                      >
                        <span style="color: black"> {{ "40" }} </span>
                      </v-progress-circular>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-btn @click="detailFreight(item)" icon color="#000000">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>

              <v-tab-item>
                <empty-data v-if="purchaseOrdersList.length == 0" :screenHeight="screenHeight" />
                <div v-else>
                <v-data-table
                    :headers="headersPurchaseOrdersTab"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :items="purchaseOrdersList"
                    item-key="sFolioId"
                  >
                    <template v-slot:item.dTotalPrice="{ item }">
                      <span> {{ "$" + formatMoney(item.dTotalPrice) + " MXN" }} </span>
                    </template>
                  </v-data-table>
                </div>
              </v-tab-item>
            </v-tabs-items>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogCreateFreight" persistent>
      <v-card class="rounded-xl elevation-16">
        <v-toolbar dense dark color="#151d27" elevation="2">
          <v-toolbar-title>Crear flete</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small @click="cancel()">
            Cerrar
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-btn text small @click="saveFreight()">
            Guardar
            <v-icon>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formCreateFreight" v-on:submit.prevent lazy-validation>
              <v-row>
                <v-col
                  cols="6"
                >
                  <v-dialog
                    ref="dialog"
                    v-model="modal"
                    :return-value.sync="forms.create.tDepartureAtWarehouse"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.create.tDepartureAtWarehouse"
                        label="Fecha de salida"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="forms.create.tDepartureAtWarehouse"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog.save(forms.create.tDepartureAtWarehouse)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col 
                  cols="6"
                >
                  <v-dialog
                    ref="dialog2"
                    v-model="modal2"
                    :return-value.sync="forms.create.tArrivalAtBorderWarehouse"
                    persistent
                    width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="forms.create.tArrivalAtBorderWarehouse"
                        label="Fecha estimada de llegada"
                        prepend-icon="mdi-calendar"
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="forms.create.tArrivalAtBorderWarehouse"
                      scrollable
                    >
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        color="primary"
                        @click="modal2 = false"
                      >
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.dialog2.save(forms.create.tArrivalAtBorderWarehouse)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-dialog>
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-text-field
                    v-model="forms.create.dFreightPrice"
                    label="Costo real de flete"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-text-field
                    v-model="forms.create.dCustomsPrice"
                    label="Costo real de aduana"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="4"
                >
                  <v-text-field
                    v-model="forms.create.dInOutPrice"
                    label="Costo real de in&out"
                    prepend-icon="mdi-currency-usd"
                    type="number"
                  ></v-text-field>
                </v-col>
                <v-col
                  cols="6"
                  style="margin-bottom: 15px;"
                >
                  <span style="font-size: 22px; font-weight: 700; color: black;">Purchase Orders disponibles</span>
                </v-col>
                <v-spacer></v-spacer>
                <v-col
                  cols="3"
                  style="margin-bottom: 15px;"
                >
                  <v-text-field
                    v-model="search"
                    class="pt-0 mt-0"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-card
                  outlined
                  style="width: 100%;"
                >
                  <v-data-table
                    :headers="headersPurchaseOrders"
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :items="purchaseOrdersList"
                    item-key="sFolioId"
                    v-model="selected"
                    :search="search"
                    show-select
                  >
                    <template v-slot:item.dTotalPrice="{ item }">
                      <span> {{ "$" + formatMoney(item.dTotalPrice) + " MXN" }} </span>
                    </template>
                  </v-data-table>
                </v-card>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay style="z-index: 99999999;" :value="overlay">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <span class="mt-3">Loading...</span>
      </div>
    </v-overlay>
  </div>
</template>
<script>
import moment from "moment-timezone";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default {
  name: "Articles",
  data() {
    return {
      search: "",
      searchInTable: "",
      iCurrentPage: 1,
      iItemsPerPage: 10,
      iNumPages: 0,
      overlay: false,
      sSearch: "",
      sCategoryId: "",
      aCategory: [],
      sSubCategoryId: "",
      aSubCategory: [],
      loading: false,
      heightTable: 0,
      bHeightTable: 0,
      screenWidth: 0,
      screenHeight: 0,
      modal: false,
      modal2: false,
      texts: "",
      e1: 1,
      dialogCreateFreight: false,
      sSearch: "",
      dialogUpdatePrices: false,
      dialogListPrices: false,
      selected: [],
      selectedArtcilesToList: [],
      exchangeRate: "",
      flete: "15,000 MXN",
      aduana: "260",
      inOut: "$ 15 p/pallet",
      takeRate: "2.5 %",
      snack: false,
      snackColor: "",
      snackText: "",
      data: [],
      results: [],
      headersPurchaseOrdersTab: [
        { text: "Folio", value: "sFolioId", align: "center" },
        { text: "Proveedor", value: "sName", align: "center" },
        { text: "Productos", value: "productList", align: "center" },
        { text: "Pallets", value: "dPalletsTotales", align: "center" },
        { text: "Total", value: "dTotalPrice", align: "center" },
      ],
      headersPurchaseOrders: [
        { text: "Folio", value: "sFolioId", align: "center" },
        { text: "Proveedor", value: "sName", align: "center" },
        { text: "Productos", value: "productList", align: "center" },
        { text: "Pallets", value: "dPalletsTotales", align: "center" },
        { text: "Total", value: "dTotalPrice", align: "center" },
      ],
      headers: [
        { text: "Folio", value: "sFolioId", align: "center" },
        { text: "Documentos", value: "sVendorWarehouse", align: "center" },
        { text: "Estado", value: "sName", align: "center" },
        { text: "PO's", value: "PurchaseOrders", align: "center" },
        { text: "Salida", value: "tDepartureAtWarehouse", align: "center" },
        { text: "Llegada", value: "tArrivalAtBorderWarehouse", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      forms: {
        update: {},
        create: {}
      },
      items: [],
      selectedCustomer: null,
      selectedWarehouse: null,
      selectedPayment: null,
      purchaseOrdersList: [],
      tableData: [],
      settings: [],
      excelTableData: [],
      customers: [],
      reportTypeGeneral: "",
      file: null,
      tab: null,
      sheetsGeneral: [],
      jsonData: null,
      dataWithHeaders: null,
      today: "",
      people: [{ name: "Perlag" }, { name: "Taylors Boys" }],
      warehouse: [{ name: "McAllen" }],
      paymentMethods: [{ name: "Credito Proplat" }, { name: "Transferencia Bancaria" }, { name: "Cheque" }],
    };
  },
  beforeMount() {
    this.texts = FILE.ordersTexts[this.selectLanguage];
    this.fillTable();
    this.getPurchaseOrders();
  },
  created() {
    window.addEventListener("load", this.contentTableHeight);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    extractValue(text) {
      const value = text.replace(/[^\d.]/g, ""); // Elimina cualquier carácter que no sea dígito o punto
      return parseFloat(value);
    },
    contentTableHeight() {
      this.heightTable = document.getElementsByClassName("tableContent");
      if (this.heightTable[0] !== undefined) {
        this.bHeightTable = this.heightTable[0].clientHeight > 760 || window.innerHeight < 800;
      }
    },
    openCreateFreight() {
      this.dialogCreateFreight = true;
      this.search = "";
      this.forms.create = {}
      if (this.$refs.formCreateFreight) {
        this.$refs.formCreateFreight.resetValidation();
      }
      this.getPurchaseOrders();
    },
    handleResize() {
      this.contentTableHeight();
      this.screenHeight = window.innerHeight - 70;
      if (window.innerWidth > 800) {
        this.screenWidth = window.innerWidth - 100;
      } else {
        this.screenWidth = window.innerWidth;
      }
    },
    fillTable() {
      this.tableData = [];

      DB.get(`${URI}/api/v1/${this.selectLanguage}/freights`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.items = response.data.purchaseOrders
          for (const item of this.items) {
            item.sName = item.sName ? item.sName : "Sin asignar"
            item.PurchaseOrders = item.PurchaseOrders ? item.PurchaseOrders : "Sin asignar"
            item.sFolioId = "F-" + item.sFolioId
          }
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        });
    },
    getPurchaseOrders() {
      this.tableData = [];

      DB.get(`${URI}/api/v1/${this.selectLanguage}/freights/purchaseOrders`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.purchaseOrdersList = response.data.purchaseOrders
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        });
    },
    detailFreight(item) {
      this.$router.push("/admin/logistic/" + item.sFreightId);
    },
    saveFreight() {
      if (this.$refs.formCreateFreight.validate()) {
        this.overlay = true;
        let data = JSON.parse(JSON.stringify(this.forms.create));

        const params = {
          sPurchaseOrders: this.selected,
          tDepartureAtWarehouse: moment(data.tDepartureAtWarehouse),
          tArrivalAtBorderWarehouse: moment(data.tArrivalAtBorderWarehouse),
          dFreightPrice: data.dFreightPrice,
          dCustomsPrice: data.dCustomsPrice,
          dInOutPrice: data.dInOutPrice
        };

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.post(`${URI}/api/v1/${this.selectLanguage}/freights/create`, params, config)
          .then((res) => {
            this.overlay = false;
            this.fillTable();
            this.cancel();
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.loading = false;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.error = true;
        this.msgError = "La solicitud tiene información no válida.";
        this.codeError = "INVALID_DATA";
      }
    },
    setDates(date) {
      const fechaConvertida = moment(date, "MM/DD/YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss.SSS ZZ");
      return fechaConvertida;
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    cancel() {
      this.dialogUpdatePrices = false;
      this.dialogEditFreightPrice = false;
      this.dialogCreateFreight = false;
      this.settings = [];
      this.results = [];
      this.e1 = 1;
      this.selected = [];
      this.file = null;
      this.loading = false;
    },
  },
  computed: {
    bMenuSidebar() {
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    sSubcategoryId() {
      return this.$store.state.sSubcategoryId;
    },
    alphabeticallyStatus() {
      return this.$store.state.bAlphabeticallyStatus;
    },
    priceStatus() {
      return this.$store.state.bPriceStatus;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.ordersTexts[this.selectLanguage];
      }
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    sCategoryId: function() {
      this.fillTable();
    },
    sSubcategoryId: function() {
      this.fillTable();
    },
    iCurrentPage: function() {
      this.fillTable();
    },
    alphabeticallyStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    priceStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.ordersTexts[this.selectLanguage];
      }
      this.fillTable();
      this.getPurchaseOrders();
    },
    tab: function() {
      switch (this.tab) {
        case 0:
          this.tableDataInProcces = [];
          this.iCurrentPage = 1;
          this.iFilter = 1;
          break;
        case 1:
          this.tableDataInProcces = [];

          this.iCurrentPage = 1;
          this.iFilter = 3;
          break;
      }
    },
  },
};
</script>
<style scoped>
.v-container-search-bar {
  background-color: #f2f2f4;
  /* border-bottom: 1px solid #d4d2c0; */
  /* padding: 15px; */
  /* display: flex; */
  /* align-items: center; */
}

.titleText {
  width: 100%;
}

/* XS */
@media (max-width: 599px) {
  .v-container-search-bar {
    /* background-color: RED; */
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .v-container-search-bar {
    /* background-color: YELLOW; */
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: BLUE; */
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: PINK; */
  }
}
/* XL */
@media (min-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: GREEN; */
  }
}
.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}
.empty-image {
  width: 200px;
}
</style>
