<template>
  <div>
    <div class="global-container-flex">
      <sidebar />
      <div :style="{ width: screenWidth + 'px' }" class="global-container-content-full-width">
        <navbar />
        <div class="v-container-search-bar" style="display: flex; flex-direction: column;">
          <div class="global-container-router-view" :style="{ height: screenHeight + 'px' }">
            <div class="v-container-search-bar mx-3 my-5">
              <div class="titleText" style="display: flex; align-items: center; flex-wrap: wrap; justify-content: flex-end;">
                <p class="mon-bold f-s45 l-spacing0 mb-0">
                  {{ "Super Buyer" }}
                </p>
                <v-spacer></v-spacer>
              </div>
            </div>
            <v-card class="mx-3 mt-2 pb-7">
              <v-row class="pa-4" style="margin: auto; justify-content: flex-end;">
                <v-autocomplete
                  :items="customers"
                  hide-details
                  chips
                  @change="getWarehouseAndPayments"
                  :loading="loadings.loadingCustomers"
                  :disabled="loadings.loadingCustomers"
                  color=""
                  label="Customer"
                  return-object
                  item-text="text"
                  item-value="sUserId"
                  style="max-width: 300px;"
                  v-model="selectedCustomer"
                >
                </v-autocomplete>
                <v-spacer></v-spacer>
                <v-col sm="3" style="align-self: center; text-align: right">
                  <v-btn
                    @click="openDialogaddProduct()"
                    :disabled="!selectedCustomer || !selectedWarehouse"
                    depressed
                    class="gradient-button mt-3"
                    color="transparent"
                  >
                    {{ "Add item" }}
                  </v-btn>
                </v-col>
              </v-row>
              <v-card v-if="selectedCustomer" class="mx-3 pb-7">
                <v-row class="pa-4" style="margin: auto; justify-content: flex-end;">
                  <v-autocomplete
                    :items="aWarehouses"
                    v-model="selectedWarehouse"
                    @change="getTemporalShoppingCart(true)"
                    hide-details
                    chips
                    return-object
                    :loading="loadings.loadingWarehouseAndPayment"
                    :disabled="loadings.loadingWarehouseAndPayment"
                    label="Warehouse"
                    item-text="sName"
                    item-value="sBorderWarehouseId"
                    style="max-width: 300px;"
                  >
                  </v-autocomplete>
                  <v-spacer></v-spacer>
                  <v-autocomplete
                    :items="paymentMethods"
                    v-model="selectedPayment"
                    hide-details
                    chips
                    :loading="loadings.loadingWarehouseAndPayment"
                    :disabled="loadings.loadingWarehouseAndPayment"
                    label="Payment"
                    item-text="sName"
                    item-value="sPaymentMethodId"
                    style="max-width: 300px;"
                  >
                  </v-autocomplete>
                </v-row>
                <template v-if="showShoppingCart">
                  <v-card class="mx-3 mt-2 pb-7 mb-4" v-for="(item, index) in items.results" :key="index">
                    <v-row class="pa-4" style="margin: auto; justify-content: flex-end;">
                      <div style="font-size: 24px; font-weight: 600;">{{ item.sName }}</div>
                      <v-spacer></v-spacer>
                      <div style="display: flex; flex-wrap: wrap; justify-content: flex-end; align-items: center;">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn v-bind="attrs" v-on="on" @click="openDialogEditFeesByProvider(item)" icon color="#000000">
                              <v-icon>mdi-pencil</v-icon>
                            </v-btn>
                          </template>
                          <span>Edit Fees</span>
                        </v-tooltip>
                        <v-text-field
                          style="width: 100px"
                          class="mr-2"
                          label="Tipo de cambio"
                          outlined
                          :disabled="true"
                          hide-details
                          :value="`${item.dRatePerUSD} USD`"
                        >
                        </v-text-field>
                        <v-text-field
                          style="width: 100px"
                          class="mr-2"
                          label="Factoraje"
                          outlined
                          :disabled="true"
                          hide-details
                          :value="`${item.dFinance} %`"
                        >
                        </v-text-field>
                        <v-text-field
                          style="width: 100px"
                          class="mr-2"
                          label="Factoraje para comprador"
                          outlined
                          :disabled="true"
                          hide-details
                          :value="`${item.dFinanceForBuyer} %`"
                        >
                        </v-text-field>
                        <v-text-field
                          style="width: 100px"
                          class="mr-2"
                          label="Take Rate"
                          outlined
                          :disabled="true"
                          hide-details
                          :value="`${item.sTakeRate} %`"
                        >
                        </v-text-field>
                        <v-text-field
                          style="width: 150px"
                          class="mr-2"
                          label="Flete"
                          outlined
                          :disabled="true"
                          hide-details
                          :value="`$ ${formatMoney(item.dFreight)} MXN`"
                        >
                        </v-text-field>
                        <v-text-field
                          style="width: 100px"
                          class="mr-2"
                          label="Comision Flete"
                          outlined
                          :disabled="true"
                          hide-details
                          :value="`${item.dFreightPercent} %`"
                        >
                        </v-text-field>
                        <v-text-field
                          style="width: 150px"
                          class="mr-2"
                          label="Aduana"
                          outlined
                          :disabled="true"
                          hide-details
                          :value="`$ ${formatMoney(item.sCustomsFee)} USD`"
                        >
                        </v-text-field>
                        <v-text-field
                          style="width: 150px"
                          label="In & Out"
                          outlined
                          :disabled="true"
                          hide-details
                          :value="`$ ${formatMoney(item.sInOutRate)} USD`"
                        >
                        </v-text-field>
                      </div>
                    </v-row>
                    <div style="display: flex; gap: 50px; overflow: auto; white-space: nowrap;" class="pa-4">
                      <v-card>
                        <v-toolbar dense dark color="#283c4d" elevation="2">
                          <v-toolbar-title>Costo del producto</v-toolbar-title>
                        </v-toolbar>
                        <v-data-table
                          :headers="headersProducto"
                          :disable-sort="true"
                          :items-per-page="iItemsPerPage"
                          hide-default-footer
                          :items="item.aProducts"
                        >
                          <template v-slot:header.dBoxesPerPallet="props">
                            <span style="white-space: nowrap;">{{ props.header.text }}</span>
                          </template>
                          <template v-slot:header.sTotalBoxes="props">
                            <span style="white-space: nowrap;" v-if="selectedCustomer.sUserId == '14eb8cfd-fe94-434f-9cf8-be4672ce8d38' && 
                              (item.sProviderId == '1a85e874-40e1-464c-9442-74665ad7d70b' || 
                              item.sProviderId == '3fddd092-9d0c-44d8-9f5c-dd6a2039261d' ||
                              item.sProviderId == '2fcf932d-ae4b-47b3-884e-ee04bbfb5867' )">
                            {{ "Total de cajas" }}</span>
                            <span v-else>{{ "Total de pallets" }}</span>
                          </template>
                          <template v-slot:item.sProductName="props">
                            <span style="white-space: nowrap;">{{ props.item.sProductName }}</span>
                          </template>
                          <template v-slot:item.cajas="props">
                            <span>{{ props.item.cajas }}</span>
                          </template>
                          <template v-slot:item.sTotalBoxes="props">
                            <span v-if="props.item.bCalculate">{{ props.item.sTotalBoxes }}</span>
                            <span v-else>{{ props.item.sTotalBoxes / props.item.dBoxesPerPallet  }}</span>
                          </template>
                          <template v-slot:item.dInspectionCost="props">
                            <span style="white-space: nowrap;">{{ "$ " + formatMoney(props.item.dInspectionCost) + " USD" }}</span>
                          </template>
                          <template v-slot:item.dUnitPriceWithFinance="props">
                            <span style="white-space: nowrap;">{{ "$ " + formatMoney(props.item.dUnitPriceWithFinance) + " MXN" }}</span>
                          </template>
                          <template v-slot:item.dUnitPrice="props">
                            <span style="white-space: nowrap;">{{ "$ " + formatMoney(props.item.dUnitPrice) + " MXN" }}</span>
                          </template>
                          <template v-slot:item.sTakeRate="props">
                            <span style="white-space: nowrap;">{{ formatMoney(props.item.sTakeRate) + " %" }}</span>
                          </template>
                          <template v-slot:item.dUnitPriceUSDWithFinance="{ item }">
                            <span style="white-space: nowrap;"> {{ "$ " + formatMoney(item.dUnitPriceUSDWithFinance) + " USD" }} </span>
                          </template>
                          <template v-slot:item.dSubTotalPrice="{ item }">
                            <span style="white-space: nowrap;"> {{ "$ " + formatMoney(item.dSubTotalPrice) + " USD" }} </span>
                          </template>
                          <template v-slot:item.actions="{ item }">
                            <div style="display: flex; align-items: center;">
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" @click="openDialogEditProduct(item)" icon color="black">
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span>Edit item</span>
                              </v-tooltip>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" @click="openDialogDeleteItem(item)" icon color="red">
                                    <v-icon>mdi-delete</v-icon>
                                  </v-btn>
                                </template>
                                <span>Delete item</span>
                              </v-tooltip>
                            </div>
                          </template>
                        </v-data-table>
                      </v-card>
                      <v-card>
                        <v-toolbar dense dark color="#283c4d" elevation="2">
                          <v-toolbar-title>Costo Logistico</v-toolbar-title>
                        </v-toolbar>
                        <v-data-table
                          :headers="headersLogistica"
                          :disable-sort="true"
                          :items-per-page="iItemsPerPage"
                          hide-default-footer
                          :items="item.aProducts"
                        >
                          <template v-slot:item.sCustomPerBox="{ item }">
                            <span style="white-space: nowrap;"> {{ "$ " + formatMoney(item.sCustomPerBox) + " USD" }} </span>
                          </template>
                          <template v-slot:item.dFreightPerBox="{ item }">
                            <span style="white-space: nowrap;"> {{ "$ " + formatMoney(item.dFreightPerBox) + " USD" }} </span>
                          </template>
                          <template v-slot:item.dTotalInOutPerBox="{ item }">
                            <span style="white-space: nowrap;"> {{ "$ " + formatMoney(item.dTotalInOutPerBox) + " USD" }} </span>
                          </template>
                          <template v-slot:item.sLogisticCost="{ item }">
                            <span style="white-space: nowrap;"> {{ "$ " + formatMoney(item.sLogisticCost) + " USD" }} </span>
                          </template>
                          <template v-slot:item.sTotal="{ item }">
                            <span style="white-space: nowrap;"> {{ "$ " + formatMoney(item.sTotal) + " USD" }} </span>
                          </template>
                          <template v-slot:item.sTotalPerBox="props">
                            <div style="white-space: nowrap;">
                              <span >{{ "$ " + formatMoney(props.item.sTotalPerBox) + " USD" }}</span>
                              <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn v-bind="attrs" v-on="on" @click="openDialogEditFinalPrice(props.item)" icon color="#000000">
                                    <v-icon>mdi-pencil</v-icon>
                                  </v-btn>
                                </template>
                                <span>Edit Final Price</span>
                              </v-tooltip>
                            </div>
                          </template>
                        </v-data-table>
                      </v-card>
                    </div>
                  </v-card>
                  <div class="mx-3" style="display: flex; justify-content: flex-end; flex-direction: column; align-items: flex-end;">
                    <div class="d-flex mt-5" style="width: 100%">
                      <p class="f-s16 mon-bold mx-2">
                        {{ "Desglose de costos" }}
                      </p>
                    </div>
                    <v-data-table
                      :no-data-text="'Sin datos'"
                      :hide-default-footer="true"
                      :disable-sort="true"
                      :headers="headersComisiones"
                      :items="SalesOrderFees"
                      class="tableOrders elevation-1"
                      style="width: 100%"
                    >
                      <template v-slot:item.dTotal="{ item }">
                        <span> $ {{ formatMoney(item.dTotal) }} USD </span>
                      </template>
                    </v-data-table>
                    <v-card
                      style="width: 100%; margin-top: 10px !important"
                      class="content-shadow mt-5 background3194FF d-flex justify-center align-center text-center"
                    >
                      <span class="text-center f-s20 mon-bold color000000 mx-3">
                        Total :
                      </span>
                      <span class="text-center f-s16 mt-1 mon-bold color000000"> $ {{ formatMoney(items.iTotal) }} USD </span>
                    </v-card>
                    <v-btn
                      :disabled="items.results.length == 0 || !selectedWarehouse || !selectedPayment"
                      depressed
                      @click="openDialogAddFolio()"
                      class="gradient-button mt-3"
                      color="transparent"
                    >
                      {{ "Send" }}
                    </v-btn>
                  </div>
                </template>
                <template v-if="loadings.loadingShoppingCart">
                  <v-skeleton-loader class="mx-auto" type="table-heading"></v-skeleton-loader>
                  <div style="display: flex; justify-content: space-between; overflow: auto; white-space: nowrap;">
                    <div>
                      <v-skeleton-loader class="mx-auto" type="card-heading"></v-skeleton-loader>
                      <v-skeleton-loader class="mx-auto" max-height="100" type="table-tbody"></v-skeleton-loader>
                    </div>
                    <div>
                      <v-skeleton-loader class="mx-auto" type="card-heading"></v-skeleton-loader>
                      <v-skeleton-loader class="mx-auto" max-height="100" type="table-tbody"></v-skeleton-loader>
                    </div>
                  </div>
                </template>
                <template v-if="!loadings.loadingShoppingCart && items.length == 0 && selectedWarehouse">
                  <div
                    class="text-center"
                    style="display: flex; flex-direction: column; justifyContent: center; alignItems: center; height: 300px;"
                  >
                    <img class="empty-image" src="@/assets/gif/gifEmpty.gif" alt="" />
                    <p class="empty-text">
                      {{ "Lo sentimos, todavía no hay elementos dentro del carrito de compras." }}
                    </p>
                  </div>
                </template>
              </v-card>
            </v-card>
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogAddProduct" persistent>
      <v-card>
        <div style="text-align: end;">
          <v-btn class="pa-0" text @click="cancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <v-card-title class="pa-5">
          <span style="font-weight: bold; font-size: 30px;">Lista de artículos</span>
          <v-spacer></v-spacer>
          <div style="display: flex; gap: 20px;">
            <v-text-field
              v-model="search"
              class="pt-0 mt-0"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </div>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-data-table
            fixed-header
            show-select
            v-model="selected"
            item-key="sProductId"
            :headers="headersExcel"
            :loading="loadings.loadingProductsTable"
            :items="tableData"
            :search="search"
            :disable-sort="true"
          >
            <template v-slot:item.aImages="{ item }">
              <img
                :src="item.aImages"
                alt="Product Image"
                style="width: 80px; height: 80px; margin: 5px; border-radius: 15px; margin-bottom: 0px;"
              />
            </template>
            <template v-slot:item.dUnitPrices="{ item }">
              <span> {{ "$" + item.dUnitPrices + " MXN" }} </span>
            </template>
            <template v-slot:item.dUnitPrice="{ item }">
              <span> {{ "$" + item.dUnitPrice + " USD" }} </span>
            </template>

            <template v-slot:item.oMeasurementUnit="{ item }">
              <span> {{ item.oMeasurementUnit.sName }} </span>
            </template>
            <template v-slot:item.bVisible="{ item }">
              <v-chip v-if="item.bVisible" outlined small class="ma-2" color="green">
                Activo
                <!-- <v-icon class="ml-1" style="font-size: 20px;">
                      mdi-play-circle-outline
                    </v-icon> -->
              </v-chip>
              <v-chip v-else class="ma-2" outlined small color="#d8686b">
                Pausado
                <!-- <v-icon style="font-size: 20px;">
                      mdi-pause
                    </v-icon> -->
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions class="pb-5">
          <v-spacer></v-spacer>
          <v-btn :disabled="selected.length == 0" depressed class="gradient-button" color="transparent" @click="addProduct()">
            {{ "Add items" }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditProduct" persistent>
      <v-card class="rounded-xl elevation-16">
        <v-toolbar dense dark color="#151d27" elevation="2">
          <v-toolbar-title>Editar producto</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialogEditProduct = false">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small @click="saveProduct()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdateProduct" v-on:submit.prevent lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dQuantity"
                    prepend-icon="mdi-package-variant"
                    label="Cantidad"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dBoxesPerPallet"
                    prepend-icon="mdi-package"
                    label="Cajas por pallet"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dUnitPrice"
                    prepend-icon="mdi-currency-usd"
                    label="Precio MXN"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.sTotalBoxes"
                    prepend-icon="mdi-package"
                    label="Total de pallets"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dInspectionCost"
                    prepend-icon="mdi-currency-usd"
                    label="Costos aduanales"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditFeesPrice" persistent max-width="1200px">
      <v-card class="rounded-xl elevation-16">
        <v-toolbar dense dark color="#151d27" elevation="2">
          <v-toolbar-title>Editar fees</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialogEditFeesPrice = false">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small @click="saveFees()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdateFees" v-on:submit.prevent lazy-validation>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dRatePerUSD"
                    prepend-icon="mdi-percent"
                    label="Tipo de cambio"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dFinance"
                    prepend-icon="mdi-percent"
                    label="Financiamiento"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dFinanceForBuyer"
                    prepend-icon="mdi-percent"
                    label="Financiamiento a comprador"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.sTakeRate"
                    prepend-icon="mdi-percent"
                    label="Take Rate"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dFreight"
                    prepend-icon="mdi-truck"
                    label="Flete"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.dFreightPercent"
                    prepend-icon="mdi-percent"
                    label="Comisión de flete"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.sCustomsFee"
                    prepend-icon="mdi-truck"
                    label="Aduana"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.sInOutRate"
                    prepend-icon="mdi-warehouse"
                    label="In & out"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditFinalPrice" persistent max-width="1200px">
      <v-card class="rounded-xl elevation-16">
        <v-toolbar dense dark color="#151d27" elevation="2">
          <v-toolbar-title>Editar final price</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialogEditFinalPrice = false">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small @click="saveFinalPrice()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text class="pa-6">
          <v-container>
            <v-form ref="formUpdateFinalPrice" v-on:submit.prevent lazy-validation>
              <v-row style="justify-content: center;">
                <v-col cols="6">
                  <v-text-field
                    v-model="forms.update.sTotalPerBox"
                    prepend-icon="mdi-currency-usd"
                    label="Final Price USD"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditExchangeRate" persistent max-width="1200px">
      <v-card class="rounded-xl elevation-16">
        <v-toolbar dense dark color="#151d27" elevation="2">
          <v-toolbar-title>Editar tipo de cambio</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn text small @click="dialogEditExchangeRate = false">
            Cerrar
            <v-icon right>mdi-close</v-icon>
          </v-btn>
          <v-btn text small @click="saveExchangeRate()">
            Guardar
            <v-icon right>mdi-check</v-icon>
          </v-btn>
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-form ref="formUpdateExchangeRate" v-on:submit.prevent lazy-validation>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="forms.update"
                    prepend-icon="mdi-currency-usd"
                    label="Take Rate"
                    maxlength="50"
                    type="number"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteProduct" persistent max-width="350px">
      <div class="container-dialog">
        <div class="display-flex align-items-center justify-content-center" style="text-align: center;">
          <img width="90px" src="@/assets/icons/delete.png" alt="" />
        </div>
        <p class="text-title mon-bold">{{ "Eliminar artículo" }}</p>
        <div class="display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular">
            {{ "¿Estás seguro de eliminar este elemento? Al realizar esta acción no podrá revertirse." }}
          </p>
        </div>
        <div class="display-flex align-items-center justify-content-center mt-9">
          <v-btn class="button-cancel mon-regular" elevation="0" @click="dialogDeleteProduct = false">
            {{ "Cancelar" }}
          </v-btn>
          <v-btn class="button-delete mon-bold ml-5" elevation="0" @click="deleteProduct()">
            {{ "Eliminar" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogAddFolio" persistent max-width="450px">
      <div class="container-dialog">
        <div style="width: 100%; text-align: end; padding-bottom: 20px;">
          <v-btn text small @click="dialogAddFolio = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        
        <p class="text-title mon-bold">{{ "Agregar Folio a la orden" }}</p>
        <div class="display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular">
            {{ "Es necesario agregar un folio a la orden." }}
          </p>
          <v-text-field
            label="Number"
            :disabled="false"
            outlined
            hide-details
            type="number"
            prefix="PP-"
            v-model="folio"
          ></v-text-field>
          <v-switch
            v-model="email"
            :label="`Send email to customer`"
          ></v-switch>
          <v-switch
            v-model="acceptOrder"
            :label="`Accept order`"
          ></v-switch>
        </div>
        <div class="display-flex align-items-center justify-content-center mt-9" style="text-align: center;">
          <v-btn class="button-delete mon-bold ml-5" elevation="0" @click="acceptOrder ? confirmOrder() : sendCartToCustomer()" :disabled="folio == null || folio == ''">
            {{  "Send" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogAddInventory" persistent>
      <div class="container-dialog">
        <div style="width: 100%; text-align: end; padding-bottom: 20px;">
          <v-btn text small @click="dialogAddInventory = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
        <p v-if="selectedWarehouse" class="text-title mon-bold">{{ "Agregar inventario al almacen: " + this.selectedWarehouse.sName }}</p>
        <v-row style="justify-content: center;">
          <v-col v-for="(item, index) in itemsInInventory" :key="index" cols="12" sm="4" md="4" lg="3" xl="2">
            <v-card class="mx-auto my-3 rounded-lg global-content-card-article">
              <div class="content-image pa-4">
                <img class="rounded-lg" height="250" width="250" :src="item.aImages.md" />
              </div>
              <v-card-text
                style=" margin-bottom:-25px; text-transform: capitalize;"
                class="f-s14 mon-bold color000000 text-article-name"
                >{{ item.sProductName }}</v-card-text
              >
              <v-card-text>
                <div class="f-s12 d-flex mon-SemiBold">
                  <v-icon class="mr-1" color="#000000" size="14px">
                    mdi-warehouse
                  </v-icon>
                  <span class="d-flex align-start" style="font-weight: bold; color: black;">
                    {{ item.sVendorWarehouseName }}
                  </span>
                </div>
                <div class="f-s12 d-flex mon-SemiBold text-article-address">
                  <v-icon class="d-flex align-start mt-1 mr-1" color="#000000" size="14px">
                    mdi-dropbox
                  </v-icon>
                  <span class="d-flex align-start" style="font-weight: bold; color: black;">
                    {{ item.dQuantity + " units" }}
                  </span>
                </div>
                <div class="f-s12 d-flex mon-SemiBold text-article-address">
                  <v-icon class="d-flex align-start mt-1 mr-1" color="#000000" size="14px">
                    mdi-dropbox
                  </v-icon>
                  <span class="d-flex align-start" style="font-weight: bold; color: black;">
                    {{ item.dBoxesPerPallet + " boxes per pallet" }}
                  </span>
                </div>
                <div class="mon-bold mt-2 text-height text-article-price" style="font-size: 20px;color: #151D27 !important;">
                  <p class="text-article-price">${{ formatMoney(item.sTotalPerBox) + " USD" }}</p>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <div class="display-flex align-items-center justify-content-center mt-9" style="text-align: end;">
          <v-btn class="button-delete mon-bold ml-5" elevation="0" @click="openDialogAddFolio()">
            {{  "Send" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <v-overlay style="z-index: 99999999;" :value="loadings.loadingAddProduct">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <span class="mt-3">Loading...</span>
      </div>
    </v-overlay>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}

      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>
<script>
import moment from "moment-timezone";

export default {
  name: "Articles",
  data() {
    return {
      search: "",
      iCurrentPage: 1,
      overlay: false,
      iItemsPerPage: 20,
      iNumPages: 0,
      iTotalItems: 0,
      sSearch: "",
      sCategoryId: "",
      aCategory: [],
      sSubCategoryId: "",
      aSubCategory: [],
      SalesOrderFees: [],
      //
      heightTable: 0,
      bHeightTable: 0,
      screenWidth: 0,
      screenHeight: 0,
      texts: "",
      e1: 1,
      dialogAddProduct: false,
      selected: [],
      selectedArtcilesToList: [],
      exchangeRate: "",
      flete: "15,000 MXN",
      aduana: "260",
      inOut: "$ 15 p/pallet",
      takeRate: "2.5 %",
      snack: false,
      snackColor: "",
      snackText: "",
      folio: "",
      data: [],
      results: [],
      headers: [
        { text: "Producto", value: "sProductName", align: "center" },
        { text: "Cajas", value: "dQuantity", align: "center" },
        { text: "Cajas por pallet", value: "dBoxesPerPallet", align: "center" },
        { text: "Costos de inspeccion", value: "dInspectionCost", align: "center" },
        { text: "Precio Bruto MXN", value: "dUnitPriceWithFinance", align: "center" },
        { text: "Precio Neto MXN", value: "dUnitPrice", align: "center" },
        { text: "Precio USD", value: "dUnitPriceUSD", align: "center" },
        { text: "Subtotal productos", value: "dSubTotalPrice", align: "center" },
        { text: "Costo de flete", value: "dFreightPerBox", align: "center" },
        { text: "Costo de Aduana", value: "sCustomPerBox", align: "center" },
        { text: "Costo de in&Out", value: "dTotalInOutPerBox", align: "center" },
        { text: "Precio por caja", value: "sTotalPerBox", align: "center" },
        { text: "Total con costo operacional", value: "sTotal", align: "center" },
      ],
      headersProducto: [
        { text: "Producto", value: "sProductName", align: "center" },
        { text: "Cajas", value: "dQuantity", align: "center" },
        { text: "Boxes per pallet", value: "dBoxesPerPallet", align: "center" },
        { text: "Total pallets", value: "sTotalBoxes", align: "center" },
        { text: "Costos aduanales", value: "dInspectionCost", align: "center" },
        { text: "Precio Bruto MXN", value: "dUnitPriceWithFinance", align: "center" },
        { text: "Precio Neto MXN", value: "dUnitPrice", align: "center" },
        { text: "Take Rate", value: "sTakeRate", align: "center" },
        { text: "Precio USD", value: "dUnitPriceUSDWithFinance", align: "center" },
        { text: "Subtotal productos", value: "dSubTotalPrice", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      headersLogistica: [
        { text: "Costo de flete", value: "dFreightPerBox", align: "center" },
        { text: "Costo de Aduana", value: "sCustomPerBox", align: "center" },
        { text: "Costo de in&Out", value: "dTotalInOutPerBox", align: "center" },
        { text: "Costo Logistico", value: "sLogisticCost", align: "center" },
        { text: "Precio por caja", value: "sTotalPerBox", align: "center" },
        { text: "Total con costo operacional", value: "sTotal", align: "center" },
      ],
      headersExcel: [
        { text: "Producto", value: "aImages", align: "center" },
        { text: "Nombre", value: "sName" },
        { text: "Proveedor", value: "sVendorEnterpriseName" },
        { text: "Tipo de caja", value: "oMeasurementUnit" },
        { text: "Inventario", value: "dInventoryQuantity" },
        { text: "Fecha disponible", value: "tProductAvailableTime" },
        { text: "Estado", value: "bVisible", align: "center" },
      ],
      headersComisiones: [
        {
          text: "Nombre",
          value: "sFeeName",
          cellClass: "mon-regular f-s12 color000000",
          class: "f-s14",
        },
        {
          text: "Subtotal de comisiones",
          value: "dTotal",
          cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
          class: "f-s14 background3194FF",
          align: "center",
        },
      ],
      selectedCustomer: null,
      selectedWarehouse: null,
      selectedPayment: null,
      tableData: [],
      excelTableData: [],
      loadings: {
        loadingWarehouseAndPayment: false,
        loadingCustomers: false,
        loadingShoppingCart: false,
        loadingProductsTable: false,
        loadingAddProduct: false,
        loadingExchangeRate: false,
      },
      showShoppingCart: false,
      customers: [],
      dialogEditProduct: false,
      dialogEditFeesPrice: false,
      dialogEditFinalPrice: false,
      dialogEditExchangeRate: false,
      dialogDeleteProduct: false,
      dialogAddFolio: false,
      forms: {
        update: {},
        delete: {},
      },
      reportTypeGeneral: "",
      file: null,
      sheetsGeneral: [],
      aWarehouses: [],
      jsonData: null,
      dataWithHeaders: null,
      today: "",
      people: [{ name: "Perlag" }, { name: "Taylors Boys" }],
      warehouse: [{ name: "McAllen" }],
      paymentMethods: [{ name: "Credito Proplat" }, { name: "Transferencia Bancaria" }, { name: "Cheque" }],
    };
  },
  beforeMount() {
    this.texts = FILE.ordersTexts[this.selectLanguage];
    this.getCustomers();
    this.getExchangeRate();
    this.today = moment().format("DD-MM-YYYY");
  },
  created() {
    window.addEventListener("load", this.contentTableHeight);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    getCustomers() {
      this.loadings.loadingCustomers = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/customers/enterprises/allCustomers`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.customers = response.data.results.map((e) => {
            return {
              ...e,
              created_at: e.created_at.substr(0, 10),
              text: e.sName + " - (" + e.sEmail + ")"
            };
          });
          this.loadings.loadingCustomers = false;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.loadings.loadingCustomers = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    getExchangeRate() {
      this.allDateTable = [];
      this.loadings.loadingExchangeRate = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/exchange-rates/current`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.exchangeRate = response.data.results;
          this.loadings.loadingExchangeRate = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loadings.loadingExchangeRate = false;
        });
    },
    fillTable() {
      this.tableData = [];
      this.loadings.loadingProductsTable = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/products`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {
          sBorderWarehouseId: this.selectedWarehouse,
        },
      })
        .then((response) => {
          this.tableData = response.data.results;
          let i = 0;
          this.tableData = response.data.results.map((e) => {
            i++;
            return {
              ...e,
              aImages: e.aImages.length > 0 ? e.aImages[0].oImages.md : require(`@/assets/images/EmptyImage.jpg`),
            };
          });
          this.iNumPages = response.data.iNumPages;
          this.iTotalItems = response.data.iTotal;
          this.loadings.loadingProductsTable = false;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loadings.loadingProductsTable = false;
        });
    },
    addProduct() {
      this.loadings.loadingAddProduct = true;
      this.selected = this.selected.map((obj) => {
        const { sProductId, dBoxesPerPallet: dQuantity } = obj;
        const sEnterpriseId = this.selectedCustomer.sEnterpriseId;
        const sCustomerId = this.selectedCustomer.sUserId;
        const sBorderWarehouseId = this.selectedWarehouse;
        return { sProductId, dQuantity, sBorderWarehouseId, sEnterpriseId, sCustomerId };
      });

      const params = this.selected;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart`, params, config)
        .then((response) => {
          this.cancel();
          this.getTemporalShoppingCart(true);
          this.loadings.loadingAddProduct = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loadings.loadingAddProduct = false;
        });
    },
    openDialogaddProduct() {
      this.dialogAddProduct = true;
      this.fillTable();
    },
    openDialogEditProduct(item) {
      this.forms.update = JSON.parse(JSON.stringify(item));
      if (this.forms.update.bCalculate) {
        this.forms.update.sTotalBoxes = this.forms.update.sTotalBoxes;
      } else {
        this.forms.update.sTotalBoxes = this.forms.update.sTotalBoxes / this.forms.update.dBoxesPerPallet;
      }
      this.dialogEditProduct = true;
    },
    openDialogEditFeesByProvider(item) {
      this.forms.update = JSON.parse(JSON.stringify(item));
      this.dialogEditFeesPrice = true;
    },
    openDialogEditFinalPrice(item) {
      this.forms.update = JSON.parse(JSON.stringify(item));
      this.dialogEditFinalPrice = true;
    },
    openDialogAddFolio() {
      this.dialogAddFolio = true;
    },
    openDialogEditExchangeRate(item) {
      this.forms.update = JSON.parse(JSON.stringify(item));
      this.dialogEditExchangeRate = true;
    },
    openDialogDeleteItem(item) {
      this.forms.delete = JSON.parse(JSON.stringify(item.sTemporalSuperBuyerId));
      this.dialogDeleteProduct = true;
    },
    getWarehouseAndPayments() {
      this.loadings.loadingWarehouseAndPayment = true;
      this.selectedWarehouse = null;
      this.selectedPayment = null;
      this.showShoppingCart = false;
      this.items = [];

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      const warehousesPromise = DB.get(
        `${URI}/api/v1/${this.selectLanguage}/administrators/${this.$store.state.sAdministratorId}/warehouses`,
        config
      ).then((response) => {
        this.aWarehouses = response.data.results.map((obj) => {
          const {
            oWarehouse: { sName },
            sBorderWarehouseId,
          } = obj;
          return { sName, sBorderWarehouseId };
        });
      });

      const paymentsPromise = DB.post(
        `${URI}/api/v1/${this.selectLanguage}/payments-methods`,
        { sEnterpriseId: this.selectedCustomer.sEnterpriseId },
        config
      ).then((response) => {
        this.paymentMethods = response.data.results;
      });

      Promise.all([warehousesPromise, paymentsPromise])
        .then(() => {
          this.loadings.loadingWarehouseAndPayment = false; // Cambiar la variable a false cuando terminen las promesas
        })
        .catch((errors) => {
          this.loadings.loadingWarehouseAndPayment = false; // Cambiar la variable a false en caso de errores
          errors.forEach((error) => {
            this.mixError(error.response.data.message, error.response.status);
          });
        });
    },
    getTemporalShoppingCart(item) {
      this.loadings.loadingShoppingCart = true;
      this.showShoppingCart = false;
      this.items = [];

      if (this.selectedWarehouse) {
        const params = {
          sBorderWarehouseId: this.selectedWarehouse,
          updateCart: item,
        };

        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.post(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart/${this.selectedCustomer.sUserId}`, params, config)
          .then((response) => {
            this.items = response.data;
            this.exchangeRate.dRatePerUSD = this.items.sRateUSD;
            this.SalesOrderFees = [
              { sFeeName: "Subtotal de artículos", dTotal: this.items.subtotal },
              { sFeeName: "Flete México", dTotal: this.items.sTotalFreight },
              { sFeeName: "Proceso Aduanal", dTotal: this.items.sTotalCustoms },
              { sFeeName: "In&Out", dTotal: this.items.sTotalInOut },
            ];
            this.showShoppingCart = true;
            this.loadings.loadingShoppingCart = false;
            this.$store.commit("refresher", false);
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.loadings.loadingShoppingCart = false;
            this.showShoppingCart = false;
          });
      } else {
        this.loadings.loadingShoppingCart = false;
      }
    },
    saveProduct() {
      if (this.$refs.formUpdateProduct.validate()) {
        this.loadings.loadingAddProduct = true;

        if (this.forms.update.bCalculate) {
          this.forms.update.sTotalBoxes = this.forms.update.sTotalBoxes;
        } else {
          this.forms.update.sTotalBoxes = this.forms.update.sTotalBoxes * this.forms.update.dBoxesPerPallet;
        }

        let temporalCart = [
          {
            sTemporalSuperBuyerId: this.forms.update.sTemporalSuperBuyerId,
            sProductId: this.forms.update.sProductId,
            sCustomerId: this.selectedCustomer.sUserId,
            dQuantity: parseInt(this.forms.update.dQuantity),
            dBoxesPerPallet: parseInt(this.forms.update.dBoxesPerPallet),
            dUnitPrice: parseFloat(this.forms.update.dUnitPrice),
            sTotalBoxes: parseInt(this.forms.update.sTotalBoxes),
          },
        ];

        const params = { temporalCart };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.patch(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart/product`, params, config)
          .then((res) => {
            this.cancel();
            this.getTemporalShoppingCart(false);
            this.loadings.loadingAddProduct = false;
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.loadings.loadingAddProduct = false;
          });
      } else {
        this.error = true;
        this.msgError = "La solicitud tiene información no válida.";
        this.codeError = "INVALID_DATA";
      }
    },
    saveFees() {
      if (this.$refs.formUpdateFees.validate()) {
        this.loadings.loadingAddProduct = true;
        let temporalCart = this.forms.update.aProducts.map((item) => {
          return {
            sProductId: item.sProductId,
            sCustomerId: this.selectedCustomer.sUserId,
            dFinance: parseFloat(this.forms.update.dFinance),
            dFinanceForBuyer: parseFloat(this.forms.update.dFinanceForBuyer),
            sInOutRate: parseFloat(this.forms.update.sInOutRate),
            sTakeRate: parseFloat(this.forms.update.sTakeRate),
            dFreight: parseFloat(this.forms.update.dFreight),
            dRatePerUSD: parseFloat(this.forms.update.dRatePerUSD),
            dFreightPercent: parseFloat(this.forms.update.dFreightPercent),
            sCustomsFee: parseFloat(this.forms.update.sCustomsFee),
          };
        });

        const params = { temporalCart };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.patch(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart/fees`, params, config)
          .then((res) => {
            this.cancel();
            this.getTemporalShoppingCart(false);
            this.loadings.loadingAddProduct = false;
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.loadings.loadingAddProduct = false;
          });
      } else {
        this.error = true;
        this.msgError = "La solicitud tiene información no válida.";
        this.codeError = "INVALID_DATA";
      }
    },
    saveFinalPrice() {
      if (this.$refs.formUpdateFinalPrice.validate()) {
        this.loadings.loadingAddProduct = true;

        let newFinalPrice = {
          sTemporalSuperBuyerId: this.forms.update.sTemporalSuperBuyerId,
          sProductId: this.forms.update.sProductId,
          sCustomerId: this.selectedCustomer.sUserId,
          sTotalPerBox: parseFloat(this.forms.update.sTotalPerBox),
          sLogisticCost: this.forms.update.sLogisticCost,
          dUnitPrice: this.forms.update.dUnitPriceWithFinance,
          dRatePerUSD: this.exchangeRate.dRatePerUSD
        }

        const params = { newFinalPrice };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.patch(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart/finalPrice`, params, config)
          .then((res) => {
            this.cancel();
            this.getTemporalShoppingCart(false);
            this.loadings.loadingAddProduct = false;
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.loadings.loadingAddProduct = false;
          });
      } else {
        this.error = true;
        this.msgError = "La solicitud tiene información no válida.";
        this.codeError = "INVALID_DATA";
      }
    },
    saveExchangeRate() {
      if (this.$refs.formUpdateExchangeRate.validate()) {
        this.loadings.loadingAddProduct = true;

        const body = {
          sCustomerId: this.selectedCustomer.sUserId,
          dRatePerUSD: parseFloat(this.forms.update),
        };
        const config = {
          headers: {
            Authorization: `Bearer ${this.$store.state.sToken}`,
          },
        };

        DB.patch(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart/exchange-rate`, body, config)
          .then((res) => {
            this.cancel();
            this.getTemporalShoppingCart(false);
            this.loadings.loadingAddProduct = false;
          })
          .catch((error) => {
            this.mixError(error.response.data.message, error.response.status);
            this.loadings.loadingAddProduct = false;
          });
      } else {
        this.error = true;
        this.msgError = "La solicitud tiene información no válida.";
        this.codeError = "INVALID_DATA";
      }
    },
    deleteProduct() {
      this.loadings.loadingAddProduct = true;
      const body = {
        sTemporalSuperBuyerId: this.forms.delete,
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart/delProduct`, body, config)
        .then((res) => {
          this.cancel();
          this.getTemporalShoppingCart(false);
          this.loadings.loadingAddProduct = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loadings.loadingAddProduct = false;
        });
    },
    sendCartToCustomer() {
      this.loadings.loadingAddProduct = true;

      const body = {
        sCustomerId: this.selectedCustomer.sUserId,
        sPaymentMethodId: this.selectedPayment,
        sFolioId: "PP-" + this.folio
      };
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(`${URI}/api/v1/${this.selectLanguage}/super-buyer-cart/sendCart`, body, config)
        .then((res) => {
          this.cancel();
          this.getTemporalShoppingCart(false);
          this.loadings.loadingAddProduct = false;
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loadings.loadingAddProduct = false;
        });
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    cancel() {
      this.dialogAddProduct = false;
      this.dialogEditFeesPrice = false;
      this.dialogEditFinalPrice = false;
      this.dialogEditProduct = false;
      this.dialogEditExchangeRate = false;
      this.dialogDeleteProduct = false;
      this.dialogAddFolio = false;
      this.search = "";
      this.results = [];
      this.e1 = 1;
      this.selected = [];
      this.file = null;
    },
    contentTableHeight() {
      this.heightTable = document.getElementsByClassName("tableContent");
      if (this.heightTable[0] !== undefined) {
        this.bHeightTable = this.heightTable[0].clientHeight > 760 || window.innerHeight < 800;
      }
    },
    handleResize() {
      this.contentTableHeight();
      this.screenHeight = window.innerHeight - 70;
      if (window.innerWidth > 800) {
        this.screenWidth = window.innerWidth - 100;
      } else {
        this.screenWidth = window.innerWidth;
      }
    },
  },
  computed: {
    bMenuSidebar() {
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    sSubcategoryId() {
      return this.$store.state.sSubcategoryId;
    },
    alphabeticallyStatus() {
      return this.$store.state.bAlphabeticallyStatus;
    },
    priceStatus() {
      return this.$store.state.bPriceStatus;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.searchbarTexts[this.selectLanguage];
        this.getTemporalShoppingCart(false);
      }
    },
    alphabeticallyStatus: function() {
      this.iCurrentPage = 1;
    },
    priceStatus: function() {
      this.iCurrentPage = 1;
    },
  },
};
</script>
<style scoped>
.empty-image {
  width: 200px;
}
.empty-text {
  text-align: center;
  font-size: 20px;
  color: #c1c1c3;
}
.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}
.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}
.content-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}
.button-delete {
  height: 40px !important;
  text-transform: initial;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.button-cancel {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  height: 40px !important;
  text-transform: initial;
  padding-left: 35px !important;
  padding-right: 35px !important;
  background-color: #ffffff !important;
}

.v-container-search-bar {
  background-color: #f2f2f4;
  /* border-bottom: 1px solid #d4d2c0; */
  /* padding: 15px; */
  /* display: flex; */
  /* align-items: center; */
}

.titleText {
  width: 100%;
}

/* XS */
@media (max-width: 599px) {
  .v-container-search-bar {
    /* background-color: RED; */
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .v-container-search-bar {
    /* background-color: YELLOW; */
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: BLUE; */
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: PINK; */
  }
}
/* XL */
@media (min-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: GREEN; */
  }
}
.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}
</style>
