<template>
  <div>
    <div class="global-container-flex">
      <sidebar />
      <div :style="{ width: screenWidth + 'px' }" class="global-container-content-full-width">
        <navbar />
        <div class="v-container-search-bar" style="display: flex; flex-direction: column;">
          <div v-if="!overlay" class="global-container-router-view" :style="{ height: screenHeight + 'px' }">
            <div class="cursor-pointer d-flex align-center mx-2 my-3" style="width: fit-content;">
              <v-btn @click="$router.go(-1)" class="button-return-icon" icon>
                <v-icon color="#283C4D" size="16px">
                  mdi-arrow-left
                </v-icon>
              </v-btn>
              <p @click="$router.go(-1)" class="button-return-text mon-regular hover-underline mb-0 ml-2 c-pointer f-s18">
                {{ texts.detailOrder.toRetrun }}
              </p>
            </div>
            <div class="v-container-search-bar mx-3 my-5">
              <div class="titleText" style="display: flex; align-items: flex-start; flex-flow: column wrap; align-content: flex-start;">
                <p class="mon-bold  l-spacing0 mb-0">
                  {{ "Código" }}
                </p>
                <p class="mon-bold  l-spacing0 mb-0">
                  {{ "F-" + items.sFolioId }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <div class="titleText" style="display: flex; align-items: flex-start; flex-flow: column wrap; align-content: flex-start;">
                <p class="mon-bold l-spacing0 mb-0">
                  {{ "Total pallets" }}
                </p>
                <p class="mon-bold l-spacing0 mb-0">
                  {{ items.dTotalPallets }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <div class="titleText" style="display: flex; align-items: flex-start; flex-flow: column wrap; align-content: flex-start;">
                <p class="mon-bold l-spacing0 mb-0">
                  {{ "Salida" }}
                </p>
                <p class="mon-bold l-spacing0 mb-0">
                  {{ items.tDepartureAtWarehouse }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <div class="titleText" style="display: flex; align-items: flex-start; flex-flow: column wrap; align-content: flex-start;">
                <p class="mon-bold l-spacing0 mb-0">
                  {{ "Llegada" }}
                </p>
                <p class="mon-bold l-spacing0 mb-0">
                  {{ items.tArrivalAtBorderWarehouse }}
                </p>
              </div>
              <v-spacer></v-spacer>
              <div
                class="
                d-flex
                justify-content-flex-end
                height-100
              "
              style="align-items: center;"
              >
                <div class="display-flex align-items-center height-100 content-gray pt-0">
                  <div>
                    <p class="text-label text-start mon-regular">
                      {{ "Estado" }}
                    </p>
                    <p class="text-label-item mon-regular" style="text-wrap: nowrap;">
                      {{ lastRecord ? lastRecord : "Sin asignar"}}
                    </p>
                  </div>
                  <v-btn elevation="0" @click="openUpdateStatus()" :disabled="lastRecordId == '4cb6f59e-40b0-4455-b1f6-f6d1d2cccf07'" class="gradient-button mon-bold ml-5" style="font-size: 12px; height: 30px">
                    {{ "Actualizar" }}
                  </v-btn>
                </div>
              </div>
            </div>
            <v-card class="mx-3 mt-2 mb-4 py-6" style="border-radius: 20px;">
              <div style="display: flex;">
                <p class="text-title-card mon-bold mb-4 mx-3">
                  {{ "Ordenes de compra" }}
                </p>
                <v-spacer></v-spacer>
                <v-tooltip left>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn @click="openDialogEditPO()" icon color="#000000" v-bind="attrs" v-on="on" class="mr-9">
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar PO's asignadas</span>
                </v-tooltip>
              </div>
              <v-card class="mx-3 mt-2 mb-4" style="border-radius: 20px;" v-for="(item, index) in items.Freights">
                <template v-if="item.sCategoryName">
                  <v-row class="pa-4" style="margin: auto; justify-content: flex-end;">
                    <div style="font-size: 24px; font-weight: 600;">{{ item.sCategoryName  }}</div>
                    <v-spacer></v-spacer>
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn @click="openDialogDeleteItem(item)" icon color="red" v-bind="attrs" v-on="on" >
                          <v-icon>mdi-trash-can</v-icon>
                        </v-btn>
                      </template>
                      <span>Eliminar PO asignada</span>
                    </v-tooltip>
                  </v-row>
                  <v-data-table
                    :headers="headersPO"
                    :disable-sort="true"
                    :items="item.aProducts"
                    hide-default-footer
                  >
                  </v-data-table>
                </template>
              </v-card>
            </v-card>
            <v-row class="mx-2">
              <v-col cols="6" md="6">
                <p class="text-title-card mon-bold mb-4">
                  {{ "Seguimiento" }}
                </p>
                <div class="container-card pa-5 seguimiento-panel">
                  <v-expansion-panels>
                    <v-expansion-panel v-for="(item, index) in statusFreight" :key="index">
                      <v-expansion-panel-header>
                        <div
                          class="
                            display-flex
                            align-items-center
                            justify-content-center number-section
                          "
                          style="display: flex; align-items: center; justify-content: center;"
                        >
                          {{ index + 1 }}
                        </div>
                        <p class="mon-medium text-title-step ml-9 mb-0">
                          {{ item.sFreightStatusName }} -
                          <span class="mon-regular">{{ item.tCreatedAtView }}</span>
                        </p>
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col class="pb-0" cols="12" md="12">
                            <div>
                              <p class="text-description-seguimiento mon-medium">
                                {{ item.sDescription }}
                              </p>
                            </div>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </div>
              </v-col>
              <v-col cols="6" sm="6">
                <div class="d-flex align-center justify-content-center">
                  <GmapMap class="content-map" :center="{lat:39.7837304, lng:-100.4458825}" :zoom="4"
                    :options="mapOptions">
                    <GmapMarker v-for="(location, index) in items.Freights"  :position="{ lat: location.dOriginWarehouseLatitude, lng: location.dOriginWarehouseLongitude }" @click="openInfoWindow(location)">
                      <GmapInfoWindow :position="location.infoWindowPosition" :options="{ pixelOffset: { width: 0, height: -5 } }">
                        <div>
                          {{ location.sVendorEnterpriseName }}
                        </div>
                      </GmapInfoWindow>
                    </GmapMarker>
                  </GmapMap>
                </div>
              </v-col>
            </v-row>
            <v-row class="mx-2 pb-5">
              <v-col cols="12" md="6">
              <p class="f-s20 mon-bold color414B54">
                {{ 'Documentos de pago' }}
              </p>
              <v-card class="pa-0 content-shadow">
                <v-data-table no-data-text='texts.detailOrder.historyFilesSection.noFilesYet' :hide-default-footer="true"
                  :disable-sort="true" :headers="headersTableCard"
                  v-if="!loadingFile"
                  :items="aOrderFilesPayment"  class="elevation-1">
                  <template v-slot:item.file={item}>
                    <div v-if="item.sUrlFile == null">
                      <v-btn :loading="loadingFile" @click="clickInputFileCustomer(item.index)"
                        class="button-add-document mon-bold mt-0" elevation="0">
                        <div class="d-flex">
                          <span
                            class=" f-s10 mon-SemiBold mt-1">{{'ADD FILE'}}</span>
                          <v-icon color="#B2B2B2" size="20px">
                            mdi-upload
                          </v-icon>
                        </div>
                        <input :ref="'inputUploadFileFreight' + item.index" :id="'inputUploadFileFreight' + item.index" hidden
                          type="file" size="60" accept=".pdf, .doc" :attr-file="item.sSalesOrderFileTypeId"
                          multiple="false" @change="uploadFilesPayment($event,item)">
                      </v-btn>
                    </div>

                    <div v-else id="status-input-zone" class="mt-0">
                      <span class="color0971FA text-decoration-underline c-pointer"
                        @click="downloadFile(item.sUrlFile)">
                        {{ item.sFileKey }}
                      </span>
                    </div>
                  </template>

                  <template v-slot:item.actions={item}>
                    <v-btn v-if="(item.sUrlFile != null)" @click='openRemoveDocument(item)' icon
                      color="#D8686A">
                      <v-icon>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                </v-data-table>
                <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="table"
                  ></v-skeleton-loader>
              </v-card>
              </v-col>
              <v-col cols="12" md="6">
                <p class="f-s20 mon-bold color414B54">
                  {{ 'Documentos de informacion' }}
                </p>
                <v-card class="pa-0 content-shadow">
                  <v-data-table no-data-text='texts.detailOrder.historyFilesSection.noFilesYet' :hide-default-footer="true"
                    :disable-sort="true" :headers="headersTableCard" v-if="!loadingFile"
                    :items="aOrderFilesInformation"  class="elevation-1">
                    <template v-slot:item.file={item}>
                      <div v-if="item.sUrlFile == null">
                        <v-btn :loading="loadingFile" @click="clickInputFileCustomer(item.index)"
                          class="button-add-document mon-bold mt-0" elevation="0">
                          <div class="d-flex">
                            <span
                              class=" f-s10 mon-SemiBold mt-1">{{'ADD FILE'}}</span>
                            <v-icon color="#B2B2B2" size="20px">
                              mdi-upload
                            </v-icon>
                          </div>
                          <input :ref="'inputUploadFileFreight' + item.index" :id="'inputUploadFileFreight' + item.index" hidden
                            type="file" size="60" accept=".pdf, .doc" :attr-file="item.sSalesOrderFileTypeId"
                            multiple="false" @change="uploadFilesPayment($event,item)">
                        </v-btn>
                      </div>

                      <div v-else id="status-input-zone" class="mt-0">
                        <span class="color0971FA text-decoration-underline c-pointer"
                          @click="downloadFile(item.sUrlFile)">
                          {{ item.sFileKey }}
                        </span>
                      </div>
                    </template>

                    <template v-slot:item.actions={item}>
                      <v-btn v-if="(item.sUrlFile != null)" @click='openRemoveDocument(item)' icon
                        color="#D8686A">
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </template>
                  </v-data-table>
                  <v-skeleton-loader
                    v-else
                    class="mx-auto"
                    type="table"
                  ></v-skeleton-loader>
                </v-card>
              </v-col>
            </v-row>
            <!-- <div class="mx-3" style="display: flex; justify-content: flex-end; flex-direction: column; align-items: flex-end; padding-bottom: 40px;">
              <div class="d-flex mt-5" style="width: 100%">
                <p class="text-label-content-shadow mon-bold">
                  {{ 'Desglose de costos' }}
                </p>
              </div>
              <v-data-table
                :no-data-text="'Sin datos'"
                :hide-default-footer="true"
                :disable-sort="true"
                :items="itemsCosts"
                :headers="headersComisiones"
                class="tableOrders elevation-1"
                style="width: 100%"
              >
                <template v-slot:item.dCosto="{ item }">
                  <span> $ {{ formatMoney(item.dCosto) }} USD </span>
                </template>
                <template v-slot:item.dCobro="{ item }">
                  <span> $ {{ formatMoney(item.dCobro) }} USD </span>
                </template>
                <template v-slot:item.dDiferencia="{ item }">
                  <span> $ {{ formatMoney(item.dDiferencia) }} USD </span>
                </template>
              </v-data-table>
            </div>  -->
          </div>
        </div>
      </div>
    </div>
    <v-dialog v-model="dialogUpdateStatus" persistent width="700">
      <v-card class="rounded-xl elevation-16">
          <v-btn text small @click="cancel()" style="position: absolute; right: 0;">
            Cerrar
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <p class="text-title mon-bold mb-9 pt-6" style="text-align: center; ">{{ "Actualización de estado" }}</p>
        <v-card-text>
          <v-container>
            <v-select
              outlined
              v-model="status"
              :items="nextStatus"
              color="#000000"
              dense
              class="global-inputs mon-regular"
              item-text="sName"
              label="Estado disponible"
              item-value="sFreightStatusId"
              clearable
            ></v-select>
            <v-textarea
              class="global-inputs mon-regular mt-5"
              outlined
              v-model="comments"
              color="#000000"
              label="Comments"
              dense
              rows="4"
              maxLength="250"
            ></v-textarea>
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="date"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="date"
                  class="mt-4"
                  label="Selecciona el dia"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="date"
                no-title
                scrollable
              >
                <v-spacer></v-spacer>
                <v-btn
                  text
                  color="primary"
                  @click="menu = false"
                >
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="$refs.menu.save(date)"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
            <v-text-field
              v-model="time"
              label="Seleccionar la hora"
              value="12:30"
              type="time"
            ></v-text-field>
            <div style="display: flex; justify-content: flex-end;">
              <v-btn elevation="0" @click="updateStatus()" :disabled="status == null || time == null || date == null" class="gradient-button mon" style="font-size: 15px; height: 35px; font-weight: 800;">
                {{ "Actualizar" }}
              </v-btn>
            </div>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogEditPO" persistent>
      <v-card class="rounded-xl elevation-16">
        <v-btn text small @click="cancel()" style="position: absolute; right: 0;">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <p class="text-title mon-bold mb-9 pt-6" style="text-align: center; ">{{ "Agregar PO's" }}</p>
        <v-row style="width: 100%;">
          <v-spacer></v-spacer>
          <v-col
            cols="2"
            style="margin-bottom: 15px;"
          >
            <v-text-field
              v-model="search"
              class="pt-0 mt-0"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
        <v-card-text>
          <v-container>
            <v-data-table
              :headers="headersPurchaseOrders"
              :loading="loading"
              loading-text="Loading... Please wait"
              :items="purchaseOrdersList"
              item-key="sFolioId"
              v-model="selected"
              :search="search"
              show-select
            >
              <template v-slot:item.dTotalPrice="{ item }">
                <span> {{ "$" + formatMoney(item.dTotalPrice) + " MXN" }} </span>
              </template>
            </v-data-table>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="3" style="text-align: end;">
                <v-btn @click="saveNewPOs()" depressed class="gradient-button mt-3" color="transparent">
                  {{ "Agregar PO's" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeletePO" persistent max-width="350px">
      <div class="container-dialog">
        <div class="display-flex align-items-center justify-content-center" style="text-align: center;">
          <img width="90px" src="@/assets/icons/delete.png" alt="" />
        </div>
        <p class="text-title mon-bold">{{ "Eliminar artículo" }}</p>
        <div class="display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular">
            {{ "¿Estás seguro de eliminar este elemento? Al realizar esta acción no podrá revertirse." }}
          </p>
        </div>
        <div class="display-flex align-items-center justify-content-center mt-9">
          <v-btn class="button-cancel mon-regular" elevation="0" @click="dialogDeletePO = false">
            {{ "Cancelar" }}
          </v-btn>
          <v-btn class="button-delete mon-bold ml-5" elevation="0" @click="deletePO()">
            {{ "Eliminar" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <v-dialog v-model="dialogDeleteDocument" persistent max-width="350px">
      <div class="container-dialog">
        <div class="display-flex align-items-center justify-content-center" style="text-align: center;">
          <img width="90px" src="@/assets/icons/delete.png" alt="" />
        </div>
        <p class="text-title mon-bold">{{ "Eliminar documento" }}</p>
        <div class="display-flex align-items-center justify-content-center">
          <p class="text-description mon-regular">
            {{ "¿Estás seguro de eliminar este documento? Al realizar esta acción no podrá revertirse." }}
          </p>
        </div>
        <div class="display-flex align-items-center justify-content-center mt-9">
          <v-btn class="button-cancel mon-regular" elevation="0" @click="dialogDeleteDocument = false">
            {{ "Cancelar" }}
          </v-btn>
          <v-btn class="button-delete mon-bold ml-5" elevation="0" @click="removeDocument()">
            {{ "Eliminar" }}
          </v-btn>
        </div>
      </div>
    </v-dialog>
    <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
      {{ snackText }}
      <template v-slot:action="{ attrs }">
        <v-btn v-bind="attrs" text @click="snack = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-overlay style="z-index: 99999999; padding-left: 100px" :value="overlay">
      <div style="display: flex; flex-direction: column; width: 200px;align-items: center;">
        <v-progress-circular :size="50" color="black" indeterminate></v-progress-circular>
        <span class="mt-3">Loading...</span>
      </div>
    </v-overlay>
  </div>
</template>
<script>

import moment from "moment-timezone";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

export default {
  name: "Articles",
  data() {
    return {
      search: "",
      iCurrentPage: 1,
      iItemsPerPage: 10,
      panel: 0,
      iNumPages: 0,
      overlay: false,
      sSearch: "",
      sCategoryId: "",
      aCategory: [],
      center: {
        lat: 39.7837304,
        lng: -100.4458825,
      },
      mapOptions: {
        scrollwheel: false,
        mapTypeControl: false,
        zoomControl: true,
        disableDoubleClickZoom: true,
        keyboardShortcuts: false,
        streetViewControl: false,
        maxZoom: 9,
        minZoom: 1,
      },
      headersComisiones: [
        { text: "Nombre", value: "sName", cellClass: "f-s14 color000000", class: "f-s14" },
        { text: "Costo", value: "dCosto", cellClass: "f-s14 color000000", class: "f-s14" },
        { text: "Cobro", value: "dCobro", cellClass: "f-s14 color000000 text-end", class: "f-s14", align: "center" },
        { text: "Diferencia", value: "dDiferencia", cellClass: "f-s14 color000000 text-end background3194FF", class: "f-s14 background3194FF", align: "center" }
      ],
      itemsCosts: [
        { sName: "Flete", dCosto: "12000", dCobro: "13000", dDiferencia: "1000" },
        { sName: "Aduana", dCosto: "290", dCobro: "500", dDiferencia: "210" },
        { sName: "In & Out", dCosto: "390", dCobro: "390", dDiferencia: "0" },
        { sName: "Costos extra", dCosto: "390", dCobro: "0", dDiferencia: "-390" },
      ],
      headersTableCard: [
          {
            text: "Nombre",
            value: "sName",
            cellClass: "color283C4D f-s14 mon-Medium l-opacity1 l-spacing0",
            class: "mon-bold f-s14",
          },
          {
            text: "Archivo",
            value: "file",
            align: "center",
            cellClass: "color283C4D f-s14 mon-Medium l-opacity1 l-spacing0",
            class: "mon-bold f-s14",
          },
          {
            text: "Acciones",
            value: "actions",
            align: "center",
            class: "mon-bold f-s14",
          },
        ],
      picker: null,
      sSubCategoryId: "",
      aSubCategory: [],
      
      loading: false,
      date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      menu: false,
      modal: false,
      time: "12:00",
      menu2: false,
      modal2: false,
      heightTable: 0,
      bHeightTable: 0,
      screenWidth: 0,
      screenHeight: 0,
      modal: false,
      modal2: false,
      texts: "",
      comments: "",
      e1: 1,
      dialogUpdateStatus: false,
      sSearch: "",
      status: "",
      dialogUpdatePrices: false,
      dialogDeleteDocument: false,
      dialogListPrices: false,
      selected: [],
      selectedArtcilesToList: [],
      exchangeRate: "",
      headersPurchaseOrders: [
        { text: "Folio", value: "sFolioId", align: "center" },
        { text: "Proveedor", value: "sName", align: "center" },
        { text: "Productos", value: "productList", align: "center" },
        { text: "Pallets", value: "dPalletsTotales", align: "center" },
        { text: "Total", value: "dTotalPrice", align: "center" },
      ],
      statusFreight: [],
      flete: "15,000 MXN",
      aduana: "260",
      inOut: "$ 15 p/pallet",
      takeRate: "2.5 %",
      snack: false,
      lastRecord: "",
      snackColor: "",
      snackText: "",
      data: [],
      results: [],
      headers: [
        { text: "Folio", value: "sFolioId", align: "center" },
        { text: "Documentos", value: "sVendorWarehouse", align: "center" },
        { text: "Estado", value: "sInOut", align: "center" },
        { text: "PO's", value: "PurchaseOrders", align: "center" },
        { text: "Salida", value: "tDepartureAtWarehouse", align: "center" },
        { text: "Llegada", value: "tArrivalAtBorderWarehouse", align: "center" },
        { text: "Acciones", value: "actions", align: "center" },
      ],
      headersPO: [
        { text: "Producto", value: "sName", align: "center" },
        { text: "Cantidad Solicitadas", value: "dOrderedQuantity", align: "center" },
        { text: "Cajas por pallet", value: "dBoxesPerPallet", align: "center" },
        { text: "Tipo de caja", value: "sMeasurementUnit", align: "center" },
        { text: "Pallets", value: "dPallets", align: "center" },
      ],
      itemsPO: [
        { sProductName: "Cilantro", dQuantity: "560", dBoxesPerPallet: "Caja de madera", sTotalBoxes: "10" },
      ],
      itemsPO2: [
        { sProductName: "Cilantro", dQuantity: "896", dBoxesPerPallet: "Caja de madera", sTotalBoxes: "16" },
      ],
      forms: {
        update: {},
        create: {},
        delete: {},
        deleteDoc: null,
      },
      items: [],
      lastRecordId: '',
      selectedCustomer: null,
      selectedWarehouse: null,
      selectedPayment: null,
      dialogDeletePO: false,
      nextStatus: [],
      purchaseOrdersList: [],
      aOrderFilesPayment: [],
      aOrderFilesInformation: [],
      aOrders: [],
      loadingFile: false,
      tableData: [],
      settings: [],
      excelTableData: [],
      customers: [],
      dialogEditPO: false,
      reportTypeGeneral: "",
      file: null,
      sheetsGeneral: [],
      jsonData: null,
      dataWithHeaders: null,
      today: "",
      status: "",
      people: [{ name: "Perlag" }, { name: "Taylors Boys" }],
      warehouse: [{ name: "McAllen" }],
      paymentMethods: [{ name: "Credito Proplat" }, { name: "Transferencia Bancaria" }, { name: "Cheque" }],
      infoWindowPosition: null,
      infoWindowText: '',
      infoWindowOpened: false
    };
  },
  beforeMount() {
    this.texts = FILE.ordersTexts[this.selectLanguage];
    this.fillTable();
    // this.getPurchaseOrders();
  },
  created() {
    window.addEventListener("load", this.contentTableHeight);
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("load", this.contentTableHeight);
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    extractValue(text) {
      const value = text.replace(/[^\d.]/g, ""); // Elimina cualquier carácter que no sea dígito o punto
      return parseFloat(value);
    },
    contentTableHeight() {
      this.heightTable = document.getElementsByClassName("tableContent");
      if (this.heightTable[0] !== undefined) {
        this.bHeightTable = this.heightTable[0].clientHeight > 760 || window.innerHeight < 800;
      }
    },
    detailItem(item) {
      this.$router.push("/admin/article-detail/" + item.sProductId);
    },
    openUpdateStatus() {
      this.dialogUpdateStatus = true;
    },
    openDialogEditPO() {
      this.dialogEditPO = true;
      this.getPurchaseOrders();
    },
    openInfoWindow(location) {
      // Cierra todas las ventanas de información abiertas
      for (let key in this.items.Freights) {
        if (Object.prototype.hasOwnProperty.call(this.items.Freights, key)) {
          this.items.Freights[key].infoWindowOpened = false;
        }
      }
      // Abre la ventana de información para el marcador seleccionado
      location.infoWindowOpened = true;
      location.infoWindowPosition = { lat: location.dOriginWarehouseLatitude, lng: location.dOriginWarehouseLongitude };
    },
    closeInfoWindow(location) {
      // Cierra la ventana de información para el marcador seleccionado
      location.infoWindowOpened = false;
    },
    handleResize() {
      this.contentTableHeight();
      this.screenHeight = window.innerHeight - 70;
      if (window.innerWidth > 800) {
        this.screenWidth = window.innerWidth - 100;
      } else {
        this.screenWidth = window.innerWidth;
      }
    },
    fillTable() {
      this.overlay = true;
      this.items = [];

      DB.get(`${URI}/api/v1/${this.selectLanguage}/freights/${this.$route.params.sFreightId}`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.getFreightStatus();
          this.items = response.data;
          // this.aOrderFilesPayment = response.data.PaymentDocuments.map(
          //   (e, i) => {
          //     if (e.sFileKey === null) {
          //       this.bImageEmty = true;
          //     }
          //     return {
          //       ...e,
          //       i: i,
          //       loadingFile: false,
          //     };
          //   }
          // );
          // this.aOrderFilesInformation = response.data.InfoDocuments.map(
          //   (e, i) => {
          //     if (e.sFileKey === null) {
          //       this.bImageEmty = true;
          //     }
          //     return {
          //       ...e,
          //       i: i,
          //       loadingFile: false,
          //     };
          //   }
          // );
          this.overlay = false;
        })
        .catch((error) => {
          console.log(error)
          this.mixError(error.response.data.message, error.response.status);
          this.overlay = false;
        });
    },
    getFreightStatus() {
      this.loaderSkeleton = true;
      this.nextStatus = [];
      
      DB.get(`${URI}/api/v1/${this.selectLanguage}/status/freights_records/${this.$route.params.sFreightId}`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
        params: {},
      })
        .then((response) => {
          this.loaderSkeleton = false;
          this.statusFreight = response.data.results;
          this.nextStatus = [response.data.nextStatus];
          this.lastRecord = this.statusFreight[this.statusFreight.length - 1].sFreightStatusName;
          this.lastRecordId = this.statusFreight[this.statusFreight.length - 1].sFreightStatusId;
        })
        .catch((error) => {
          this.loaderSkeleton = false;
        });
    },
    updateStatus() {
      this.overlay = true;

      let created = moment(this.date + " " + this.time, "YYYY/MM/DD hh:mm").format("YYYY-MM-DD HH:mm:ss.SSS ZZ");
      const params = {
        sFreightId: this.$route.params.sFreightId,
        sFreightStatusId: this.status,
        sDescription: this.comments,
        created_at: created,
        updated_at: created
      }; 

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/status/freights_records/newStatus`, params, config)
        .then((response) => {
          this.fillTable();
          this.cancel();
          this.overlay = false;
        })
        .catch((error) => {
          this.overlay = false;
        });
    },
    saveNewPOs() {
      this.overlay = true;

      const params = {
        sPurchaseOrders: this.selected,
        sFreightId: this.$route.params.sFreightId
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.post(`${URI}/api/v1/${this.selectLanguage}/freights/edit`, params, config)
        .then((res) => {
          this.overlay = false;
          this.fillTable();
          this.getPurchaseOrders();
          this.cancel();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    openDialogDeleteItem(item) {
      this.forms.delete = JSON.parse(JSON.stringify(item.sPurchaseOrderId));
      this.dialogDeletePO = true;
    },
    deletePO() {
      this.overlay = true;
      const params = {
        sPurchaseOrderId: this.forms.delete,
      };

      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.patch(`${URI}/api/v1/${this.selectLanguage}/freights/delete`, params, config)
        .then((res) => {
          this.overlay = false;
          this.fillTable();
          this.getPurchaseOrders();
          this.cancel();
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getPurchaseOrders() {
      this.tableData = [];

      DB.get(`${URI}/api/v1/${this.selectLanguage}/freights/purchaseOrders`, {
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.purchaseOrdersList = response.data.purchaseOrders
          this.purchaseOrdersList = this.purchaseOrdersList.map(order => ({
            ...order,
            selected: true,
          }));
        })
        .catch((error) => {
          this.mixError(error.response.data.message, error.response.status);
          this.loading = false;
        });
    },
    setDates(date) {
      const fechaConvertida = moment(date, "MM/DD/YYYY hh:mma").format("YYYY-MM-DD HH:mm:ss.SSS ZZ");
      return fechaConvertida;
    },
    formatMoney: function(money) {
      return FORMAT_MONEY(money);
    },
    cancel() {
      this.search = "";
      this.time = "12:00";
      this.dialogEditPO = false;
      this.status = null;
      this.comments = null;
      this.dialogUpdatePrices = false;
      this.dialogEditFreightPrice = false;
      this.dialogUpdateStatus = false;
      this.forms.create = {};
      this.settings = [];
      this.results = [];
      this.e1 = 1;
      this.selected = [];
      this.file = null;
      this.dialogDeletePO = false;
      this.loading = false;
    },
    clickInputFileCustomer(id) {
      var inputUploadFile = "inputUploadFileFreight" + id;
      document.getElementById(inputUploadFile).click();
    },
    uploadFilesPayment(e, item) {
      this.overlay = true;
      const file = e.target.files[0];
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      const payload = {};

      var form = new FormData();
      form.append("file", file);

      //AQUI SE SUBE EL ARCHIVO
      DB.patch(
        `${URI}/api/v1/${this.selectLanguage}/freights/${this.$route.params.sFreightId}/files/${item.sFreightsFileTypeId}`,
        form,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.fillTable();
          this.loadingFile = false;
          this.overlay = false;
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.loadingFile = false;
          this.overlay = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    openRemoveDocument(item) {
      this.forms.deleteDoc = item;
      this.dialogDeleteDocument = true;
    },
    removeDocument() {
      this.overlay = true;
      this.dialogDeleteDocument = false;
      const config = {
        headers: {
          Authorization: `Bearer ${this.$store.state.sToken}`,
        },
      };

      DB.delete(
        `${URI}/api/v1/${this.selectLanguage}/freights/${this.$route.params.sFreightId}/files/${this.forms.deleteDoc.sFreightsFileTypeId}`,
        config
      )
        .then((response) => {
          this.mixSuccess(response.data.message);
          this.fillTable();
          this.loadingFile = false;
          this.$store.commit("refresher", true);
        })
        .catch((error) => {
          this.loadingFile = false;
          this.mixError(error.response.data.message, error.response.status);
        });
    },
    downloadFile: function(sFileUrl) {
      window.open(sFileUrl, "blank_");
    },
  },
  computed: {
    bMenuSidebar() {
      return this.$store.state.bMenuSidebar;
    },
    selectLanguage() {
      return this.$store.state.language;
    },
    sSubcategoryId() {
      return this.$store.state.sSubcategoryId;
    },
    alphabeticallyStatus() {
      return this.$store.state.bAlphabeticallyStatus;
    },
    priceStatus() {
      return this.$store.state.bPriceStatus;
    },
  },
  watch: {
    selectLanguage() {
      if (this.selectLanguage) {
        this.texts = FILE.searchbarTexts[this.selectLanguage];
      }
    },
    sSearch: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    sCategoryId: function() {
      this.fillTable();
    },
    sSubcategoryId: function() {
      this.fillTable();
    },
    iCurrentPage: function() {
      this.fillTable();
    },
    alphabeticallyStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    priceStatus: function() {
      this.iCurrentPage = 1;
      this.fillTable();
    },
    selectLanguage: function() {
      if (this.selectLanguage) {
        this.texts = FILE.searchbarTexts[this.selectLanguage];
      }
      this.fillTable();
    },
  },
};
</script>
<style scoped>
.v-container-search-bar {
  background-color: #f2f2f4;
  /* border-bottom: 1px solid #d4d2c0; */
  /* padding: 15px; */
  /* display: flex; */
  /* align-items: center; */
}
.button-add-document {
  width: 100% !important;
  height: 70% !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #70707080;
  border-radius: 20px;
  opacity: 1;
}
.titleText {
  width: 100%;
}

/* XS */
@media (max-width: 599px) {
  .v-container-search-bar {
    /* background-color: RED; */
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .v-container-search-bar {
    /* background-color: YELLOW; */
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: BLUE; */
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: PINK; */
  }
}
/* XL */
@media (min-width: 1904px) {
  .v-container-search-bar {
    display: flex;
    /* background-color: GREEN; */
  }
}
.gradient-button {
  background: transparent linear-gradient(228deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}
.empty-image {
  width: 200px;
}
.button-return-icon {
  border-radius: 100%;
  width: 25px;
  height: 25px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}

.v-data-table-header-mobile {
  display: none;
}
.text-order-label {
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  opacity: 1 !important;
  margin-bottom: 0px !important;
}
/* .table-article-order .v-data-table__wrapper table thead tr th {
  font-size: 14px !important;
  letter-spacing: 0px !important;
  color: #000000 !important;
  opacity: 1 !important;
margin-bottom: 0px !important;
} */

.seguimiento-panel .theme--light.v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon .v-icon {
  color: #000000;
}

.full-height-image-skeleton .v-skeleton-loader__image {
  height: 100% !important;
}
</style>

<style scoped>
.content-gray {
  background: #ebebeb 0% 0% no-repeat padding-box;
  border-radius: 5px;
  padding: 5px 15px;
}

.padding-lateral-max {
  padding-left: 80px !important;
  padding-right: 80px !important;
}

.text-label-payments {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-label-payments-item {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-price-item {
  font-size: 14px;
}

.text-price {
  font-size: 14px;
}

.cursor-pointer:hover {
  cursor: pointer;
}

/* start: carousel images */
.content-carousel-effect {
  overflow-x: auto;
  overflow-y: hidden !important;
}

.content-carousel-effect::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.content-carousel-effect {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.container-carousel {
  width: 100%;
}

.content-image-carousel {
  width: 90px;
  height: 90px;
}

.content-image-carousel img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-image-carousel img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
  }
}

/* end: carousel images */
.button-delete-proof-payment {
  min-width: 40px !important;
  height: 40px !important;
  background: #d8686a 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  opacity: 1;
  position: absolute;
  right: 10px;
  top: 10px;
}

.text-prof-payment-name {
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0971fb;
  opacity: 1;
  margin-bottom: 0px;
}

.text-prof-payment-name:hover {
  text-decoration: underline;
  cursor: pointer;
}

.button-show-dispute {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 12px;
  opacity: 1;
  text-align: center;
  font-size: 12px;
  letter-spacing: 0px;
  color: #283c4d;
  background-color: transparent !important;
  text-transform: initial;
  padding: 0px 8px !important;
}

.text-order-label {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-order-product {
  text-align: left;
  font-size: 12px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-warehouse-buyer {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 5px;
}

.content-shadow {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
  padding: 20px;
}

.text-label {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-label-item {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-description-seguimiento {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #00000080;
  opacity: 1;
  margin-bottom: 0px;
}

.seguimiento-panel .v-expansion-panel-header {
  padding: 0px;
}

.seguimiento-panel .content-left-line {
  border-left: 1px solid #00000029;
  margin-left: -15px;
  padding: 0px 10px;
}

.seguimiento-panel .text-title-step {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
}

.seguimiento-panel .theme--light.v-expansion-panels .v-expansion-panel:not(:first-child)::after {
  border: none;
}

.seguimiento-panel .v-stepper--vertical {
  padding-bottom: 0px;
}

.number-section {
  background-color: black;
  color: white;
  height: 28px;
  width: 28px;
  border-radius: 100%;
  position: absolute;
  margin-right: 10px;
}
.content-map {
  width: 100%;
  height: 350px;
}

.seguimiento-panel .v-expansion-panel::before {
  box-shadow: none !important;
}

.button-start {
  height: 40px !important;
  border: 1px solid #283c4d;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.button-finalize {
  height: 40px !important;
  background: transparent linear-gradient(256deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  text-transform: initial;
}

.seguimiento-panel .v-expansion-panel::before {
  box-shadow: none !important;
}

.text-payment-card {
  text-align: center;
  display: flex;
  font-size: 14px;
  letter-spacing: 0px;
  color: #40ae8b;
  opacity: 1;
  margin-bottom: 0px;
}

.text-payment-card-number {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 0.5;
  margin-bottom: 0px;
}

.text-map-title {
  text-align: left;
  font-size: 16px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-map-subtitle {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 0px;
}

.text-product-title-info {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-product-title-subinfo {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-product-title {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.subtext-product-text {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #b2b2b2;
  opacity: 1;
  margin-bottom: 0px;
}
.button-add-document_New {
  background-color: #ffffff !important;
  width: 100%;
  border: 1px dashed #707070;
  height: fit-content !important;
}
.button-add-document {
  width: 200px !important;
  height: 70% !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #70707080;
  border-radius: 20px;
  opacity: 1;
}
.text-button-document {
  text-align: center;
  font-size: 11px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #cccccc;
  opacity: 1;
  text-transform: initial;
}

.text-link {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #0971fb;
  opacity: 1;
  margin-bottom: 0px;
}

.text-link:hover {
  cursor: pointer;
  text-decoration: underline;
}

.text-cost-mexico {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-cost-mexico-price {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  color: #9aa6b1;
  opacity: 1;
  margin-bottom: 0px;
}

.text-total-price {
  text-align: right;
  font-size: 14px;
  letter-spacing: 0px;
  margin-bottom: 0px;
}

.border-bottom {
  border-bottom: 1px solid #e0e0e0;
}

.text-title-card {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #414b54;
  opacity: 1;
  margin-bottom: 0px;
}

.text-aprox {
  text-align: left;
  font-size: 13px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-aprox-date {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-total-order {
  text-align: right;
  font-size: 16px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-total-money {
  text-align: left;
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.container-card {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 9px;
  opacity: 1;
}

.height-100 {
  height: 100%;
}

.width-100 {
  width: 100%;
}

.text-folio {
  text-align: left;
  font-size: 35px;
  letter-spacing: 0px;
  color: #151d27;
  opacity: 1;
  margin-bottom: 0px;
}

.text-creation {
  /* text-align: right; */
  font-size: 13px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  margin-bottom: 0px;
}

.text-date {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 0.51;
  margin-bottom: 0px;
}

.text-status {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.text-status-result {
  text-align: center;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}

.icon-return {
  border-radius: 100%;
  width: 27px;
  height: 27px;
  background: transparent linear-gradient(265deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
}

.text-return {
  text-transform: initial;
  margin-left: 5px;
  font-size: 16px;
}

.content-card-paymentmethod {
  height: 230px;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.img-paymentmethod_New {
  width: 150px;
  margin-top: 10px;
}
.img-paymentmethod {
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 50%;
  object-fit: contain;
}

/* imagenes articulos */
.products-vendor .content-image {
  width: 40px;
  min-width: 40px;
  max-width: 40px;
  height: 40px;
  min-height: 40px;
  max-height: 40px;
}

.products-vendor .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .products-vendor .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 10px;
  }
}

/* imagenes seguimiento */
.content-fixed-image {
  width: 125px;
  max-height: 350px;
  overflow: auto;
  padding: 10px;
}

.content-fixed-image .content-image {
  width: 100%;
  height: 100px;
  opacity: 1;
  margin-bottom: 10px;
}

.content-fixed-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-fixed-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

.content-resize-image {
  width: calc(100% - 125px);
  height: 350px;
  padding: 10px;
}

.content-resize-image .content-image {
  width: 100%;
  height: 100%;
  opacity: 1;
  margin-bottom: 10px;
}

.content-resize-image .content-image img {
  width: 100%;
  height: auto;
}

@supports (object-fit: cover) {
  .content-resize-image .content-image img {
    height: 100%;
    object-fit: cover;
    object-position: center center;
    border-radius: 23px;
  }
}

@media (max-width: 600px) {
  .resize-content {
    display: block;
  }

  .content-fixed-image {
    display: flex;
    width: 100%;
    align-items: center;
  }

  .content-fixed-image .content-image {
    width: 130px;
    margin: 1px;
    padding: 4px;
  }

  .content-resize-image {
    width: 100% !important;
  }
}
.container-dialog {
  width: 100%;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  opacity: 1;
  padding: 25px;
  position: relative;
}

.button-delete {
  height: 40px !important;
  text-transform: initial;
  background: transparent linear-gradient(257deg, #ffc556 0%, #fffc81 100%) 0% 0% no-repeat padding-box;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  padding-left: 35px !important;
  padding-right: 35px !important;
}
.button-cancel {
  box-shadow: 0px 3px 6px #00000029;
  border: 1px solid #283c4d;
  border-radius: 10px;
  opacity: 1;
  text-align: center;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  height: 40px !important;
  text-transform: initial;
  padding-left: 35px !important;
  padding-right: 35px !important;
  background-color: #ffffff !important;
}
.text-title {
  text-align: center;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  font-size: 25px;
}
.text-label-content-shadow {
  text-align: left;
  font-size: 18px;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  margin-bottom: 5px;
}

.button-add-document-invoice {
  width: 50% !important;
  height: 30px !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  border: 1px dashed #707070;
  border-radius: 10px;
  opacity: 1;
  z-index: 1;
  position: relative;
  text-transform: initial;
  font-size: 12px;
}
.text-files-client-name {
  text-align: left;
  font-size: 14px;
  letter-spacing: 0px;
  color: #283c4d;
  opacity: 1;
  margin-bottom: 0px;
}
</style>
