var texts = {
  en: {
    table: {
      headersInProcess: [
        {
          text: "Sale order",
          value: "sFolioId",
          cellClass: "mon f-s12 color000000",
          class: "f-s14",
        },
        {
          text: "Supplier",
          value: "sEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Customer",
          value: "sCustomerEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Total",
          value: "dTotalPrice",
          // cellClass: "mon f-s14 text-center color000000 ",
          // class: "f-s14 nolinebreak",
          cellClass: "mon f-s14 color000000 text-end nolinebreak",
          class: "f-s14 text-center ",
        },
        {
          text: "Creation date",
          value: "tCreatedAtView",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Ready to pick up",
          value: "sCurrentStatusName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Payment",
          value: "sPaid",
          cellClass: "mon f-s14 color000000 text-center",
          class: "f-s14 text-center ",
        },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersInterrupted: [
        {
          text: "Sale order",
          value: "sFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Supplier",
          value: "sEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Customer",
          value: "sCustomerEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Creation date",
          value: "tCreatedAtView",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "last status",
          value: "sCurrentStatusName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Payment",
          value: "sPaid",
          cellClass: "mon f-s14 color000000 text-center",
          class: "f-s14 text-center ",
        },
        { text: "Actions", value: "actions", align: "center" },
      ],
      headersFinaly: [
        {
          text: "Sale order",
          value: "sFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Supplier",
          value: "sEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Customer",
          value: "sCustomerEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Total",
          value: "dTotalPrice",
          cellClass: "mon f-s14 color000000 text-end nolinebreak",
          class: "f-s14 text-center ",
        },
        {
          text: "Finish date",
          value: "tSalesOrderStatusDateView",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Exp. invoice",
          value: "iBillDays",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Payment",
          value: "sPaid",
          cellClass: "mon f-s14 color000000 text-center",
          class: "f-s14 text-center ",
        },
        { text: "Actions", value: "actions", align: "center" },
      ],
      actions: {
        labels: {
          textEdit: "Edit",
          textActivate: "Enabled",
          textDeactivate: "Disabled",
          textRecoveryPassword: "Reset password",
          textDelete: "Delete",
          textDetails: "Detail",
        },
      },
      status: {
        textActivate: "Enabled",
        textDeactivate: "Disabled",
        doesNotApply: "Does not apply",
        day: "Day",
        days: "Days ",
      },
    },
    pagination: {
      paginationRowPerPage: "Rows per page",
      of: "of",
    },
    dialogDelete: {
      textTitle: "Customers",
    },
    dialogEdit: {
      title: "Edit customer",
      description:
        "Edit the information to display for the customer in the list.",
      placeholders: {
        latitude: "Capture latitude",
        longitude: "Capture longitude",
        blueBook: "Capture the id of the blue book",
        description: "Capture the description",
        email: "Capture email",
        address: "Capture the address",
        city: "Capture the city",
        zipCode: "Capture the zip code",
        name: "Capture the name",
        license: "Capture the PACA license number",
        phoneNumber: "Capture the phone number",
        state: "Select the state",
        taxPayerId: "Capture the tax payer id",
        country: "Select the country",
      },
      buttons: {
        edit: "Edit",
        cancel: "Cancel",
      },
      labels: {
        latitude: "Latitude",
        longitude: "Longitude",
        blueBook: "Blue book Id",
        description: "Description",
        email: "Email",
        address: "Address",
        city: "City",
        zipCode: "Zip code",
        name: "Name",
        license: "PACA license",
        phoneNumber: "Phone number",
        state: "State",
        taxPayerId: "Tax payer id",
        country: "Country",
      },
    },
    tooltip: {
      search: "Search",
    },
    tabsItems: {
      inProcess: "In process",
      finished: "Finished",
    },
    tabsItemsFreight: {
      inProcess: "Freights",
      interrupted: "Unassigned PO's",
    },
    detailOrder: {
      estimateddeliverydate: "Estimated delivery date",
      pleaseEnterDate: "Please enter a date...",
      next: "Next",
      update: "Update",
      estimateddeliverydateTitle: "Change of estimated delivery date.",
      estimateddeliverydateDescritpion:
        "This action will change the estimated delivery date..",
      estimateddeliverydateQuestion:
        "Do you want to continue with this action?",
      estimateddeliverydateCancel: "Cancel",
      estimateddeliverydateChange: "Update",
      toRetrun: "Return",
      creation: "Creation",
      ending: "Ending",
      status: "Status",
      toUpdate: "Update",
      payment: "Payment",
      billExpiration: "bill expiration",
      orderTotal: "Order Total",
      vendorAndCustomerSection: {
        vendor: "Vendor",
        customer: "Customer",
        originWarehouse: "Origin warehouse",
        destinationWarehouse: "Destination warehouse",
        warehouseManagerName: "Warehouse manager name",
        phone: "Phone",
        email: "Email",
      },
      vendorAndCustomerFilesSection: {
        vendorFile: "General supplier documents",
        customerFile: "General customer documents",
        proofOfPayment: "Proof of payment",
        invoice: "Invoice",
        downloadFile: "Download file",
        addFile: "Add file",
        thereIsNotDocumentToDownload: "There is not document to download",
        delete: {
          title: "Delete file",
          description:
            "Are you sure to delete the file? By performing this action it cannot be reversed ",
          note: "",
          chkLabel: "",
        },
      },
      requiredFilesSection: {
        requiredFiles: "Mandatory documents",
        headers: { name: "Name", file: "File", actions: "Actions" },
        headersTableCard: [
          {
            text: "Name",
            value: "sPurchaseOrderFileTypeName",
            cellClass: "mon f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "File",
            value: "file",
            cellClass: "mon f-s14 color000000",
            class: "f-s14",
          },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            class: "mon-bold f-s14",
          },
        ],
        reliabilityCertificate: "Reliability certificate",
        customsProof: "Customs proof",
        addFile: "Add file",
        delete: {
          title: "Delete file",
          description:
            "Are you sure to delete the file? By performing this action it cannot be reversed ",
          note: "",
          chkLabel: "",
        },
      },
      historyFilesSection: {
        historyFiles: "Record files",
        headers: {
          name: "Name",
          date: "Date",
          status: "Status",
          actions: "Actions",
        },
        headersTableCard: [
          {
            text: "Name",
            value: "sPurchaseOrderFileTypeName",
            cellClass: "mon f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Date",
            value: "tCreatedAtView",
            cellClass: "mon f-s14 color000000",
            class: "f-s14",
          },
          {
            text: "Status",
            value: "sPurchaseOrderStatusName",
            cellClass: "mon f-s14 color000000",
            class: "f-s14",
          },
          {
            text: "Actions",
            value: "actions",
            align: "center",
            class: "mon-bold f-s14",
          },
        ],
        noFilesYet: "No Files Yet",
      },
      purchaseOrdersSection: {
        purchaseOrdersAndCommissions: "Purchase orders and commissions",
        purchaseOrders: "Purchase orders",
        unitsRequested: "Units requested",
        finalunits: "Final units",
        pricePerUnitRequested: "Price per unit requested",
        finalPrice: "Final unit price (vendor)",
        finalPriceCustomer: "Final unit price (customer)",
        finalSubtotal: "Final subtotal",
        baseAmount: "Base amount",
        commissions: "Shipping breakdown",
        fixedRate: "Fixed rate",
        finalPrice: "Final unit price (vendor)",
        finalPriceCustomer: "Final unit price (customer)",
        freightCostMexico: "Freight Cost (Mexico)",
        customsCostUS: "Customs Cost (US)",
        freightCostUS: "Freight Cost(US)",
        inOut: "In / Out",
        customs: "Customs",
        subTotal: "Subtotal",
        sReverse:"Reverse",
        sCalculate:"Calculate",
        saveChange: "Save changes",
        addProduct: "Add articles",
        infromationbLock:
          "When selecting the row, the order will be taken into account for its change",
        infromationbLockComissions:
          "When selecting the row, the comissions will be taken into account for its change",
        subtotalOfOrders: "Subtotal of orders",
        subtotalOfComissions: "Subtotal of comissions",
        name: "Name",
        warehouseHandling: "Warehouse handling",
        noOfPallets: "Num pallets",
        pricePerPallet: "Price per pallet",
        headersTableCard: [
          {
            text: "Name",
            value: "sProductName",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Selecting the row",
            value: "bLock",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s12",
            align: "center",
          },
          {
            text: "Units requested",
            value: "dOrderedQuantity",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Final units",
            value: "dDeliveredQuantity",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 classHiddenOrders",
            align: "center",
          },
          {
            text: "Price per unit (vendor)",
            value: "dOrderedUnitPrice",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Final price per unit (vendor)",
            value: "dDeliveredUnitPrice",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 classHiddenOrders",
            align: "center",
          },
          {
            text: "Price per unit (customer)",
            value: "dOrderedUnitPriceClient",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Final price per unit (customer)",
            value: "dDeliveredUnitPriceClient",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Subtotal sale amount (vendor)",
            value: "dFinalPrice",
            cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
          {
            text: "Subtotal sale amount (customer)",
            value: "dFinalPriceClient",
            cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
        ],
        headersTableWarehouseCard: [
          {
            text: "Name",
            value: "name",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Num pallets",
            value: "dTotalPallets",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Price per pallet",
            value: "dInOutRateValue",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Subtotal",
            value: "dTotalPrice",
            cellClass:
              "mon-bold f-s12 color000000 text-center background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
        ],
        headersTableFeesCard: [
          {
            text: "Name",
            value: "sFeeName",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Select row",
            value: "bLock",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Base amount",
            value: "dBaseAmount",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Commissions",
            value: "dPercentage",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Fixed fee",
            value: "dAmount",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Subtotal of commissions",
            value: "dTotal",
            cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
        ],
        headersTableFeesCardWithoutSelected: [
          {
            text: "Name",
            value: "sFeeName",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Base amount",
            value: "dBaseAmount",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Commissions",
            value: "dPercentage",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Fixed fee",
            value: "dAmount",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Subtotal of commissions",
            value: "dTotal",
            cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
        ],
        noFilesYet: "No orders yet",
      },
      warehouseSection: {
        warehouse: "Warehouse",
        shippingWarehouse: "Shipping warehouse",
        deliveryWarehouse: "Delivery warehouse",
      },
      paymentMethodSection: {
        paymentMethod: "Payment method",
        payment: "Payment",
        markAsPaid: "Mark as paid",
        markCharged: "Mark Charged",
        titleCharged: "Sale order",
        titlepaid: "Purchase order",
        paid: "Paid",
        charged: "Charged",
        titleDialogPaid: "Change of status",
        detailDialogPaid:
          "Marking a sales order as charged is an irreversible action.",
        titleDialogCharged: "Change of status",
        detailDialogCharged:
          "Marking a purchase order as paid is an irreversible action.",
        questionDialog: "You want to continue with this action?",
        cancel: "Cancel",
        pay: "Confirm",
      },
      trackingSection: {
        NoPictures: "No pictures",
        description: "Description",
        tracking: "Tracking",
        orderReceived: "Order received",
        inPreparation: "In preparation",
        inTransit: "In transit",
        inTheProcessOfBorderCrossing: "In the process of border crossing",
        successfulCrossover: "Successful crossover",
        borderWarehouse: "Border warehouse",
        acceptedAtBorderWarehouse: "Accepted at border warehouse",
        transferred: "Transferred",
        onDeliveryRoute: "On delivery route",
        delivered: "Delivered",
        finished: "Finished",
        mouseOverImageToZoom: "Hover over the image to zoom in.",
      },
    },
    dialogAddProduct: {
      sSalesOrderExchangeRate: "SALES ORDER EXCHANGE RATE",
      sPer:"per",

      titleAdd: "Add an item to the order",
      selectCat: "Select category",
      selectSub: "Select subcategory",
      selectProduct: "Select item",
      selectCant: "Queantity",
      selectCat1: "Select category...",
      selectSub1: "Select subcategory...",
      selectProduct1: "Select item...",
      selectCant1: "33",

      sWeigthPer: "Weight per",
      sAproxPiecesPer: "Aprox. pieces per",
      sNumberOf: "Number of",
      sPerPallet: "per pallet",
      sStorageTemperature: "Storage temperature",
      sTransportationTemperature: "Transportation temperature",
      sPricePerPallet: "Price per pallet",
      sAddSalesOrder: "Add to the sales order",
      sInventory: "Inventory",
      sQuantity: "Quantity",
      sMinimumSellingAmount: "Minimum selling amount",
      sAvailablePalletsOf: "available pallets of",
      sTotal: "total",

      buttonsActions: {
        return: "cancel",
        addProductbtn: "Add item",
      },
    },
    dialogEditFiles: {
      title: "Edit history file",
      nameFile: "File name",
      dateFile: "Date",
      status: "Status",
      file: "Document",
      buttonsActions: {
        cancel: "Cancel",
        saveChange: "Save changes",
      },
    },
    dialogUpdateStatus: {
      statusUpdate: "Status update",
      availableStates: "Available states",
      selectAState: "Select a state",
      comments: "Comments",
      clickOrDragToAddImage: "Click or drag to add image",
      textResponseLimitImages: "Exceeds the number of images allowed",
      youNeedToAddTheRequiredFiles: "You need to add the mandatory documents",

      buttonsActions: {
        return: "Return",
        updateStatus: "Update status",
      },
    },
    searchbar: {
      search: "Enter order folio ...",
      startDate: "Start date",
      finalDate: "Final date",
    },
    deleteProducts: {
      title: "Delete item ",
      description: "Are you sure to delete the item?",
      note: "",
      chkLabel: "",
      cancel: "Cancel",
      delete: "Delete",
    },
    informationNotYetRegistered: "Information not yet registered",
  },
  sp: {
    table: {
      headersInProcess: [
        {
          text: "Pedido de venta",
          value: "sFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Proveedor",
          value: "sEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Comprador",
          value: "sCustomerEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Total",
          value: "dTotalPrice",
          cellClass: "mon f-s14 color000000 text-end",
          class: "f-s14 text-center ",
        },
        {
          text: "Fecha de creación",
          value: "tCreatedAtView",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Listo para recoger",
          value: "sCurrentStatusName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Pago",
          value: "sPaid",
          cellClass: "mon f-s14 color000000 text-center",
          class: "f-s14 text-center ",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
      ],
      headersInterrupted: [
        {
          text: "Pedido de venta",
          value: "sFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Proveedor",
          value: "sEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Comprador",
          value: "sCustomerEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Fecha de creación",
          value: "tCreatedAtView",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Último estado",
          value: "sCurrentStatusName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Pago",
          value: "sPaid",
          cellClass: "mon f-s14 color000000 text-center",
          class: "f-s14 text-center ",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
      ],
      headersFinaly: [
        {
          text: "Pedido de venta",
          value: "sFolioId",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Proveedor",
          value: "sEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Comprador",
          value: "sCustomerEnterpriseName",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Total",
          value: "dTotalPrice",
          cellClass: "mon f-s14 color000000 text-end nolinebreak",
          class: "f-s14 text-center ",
        },
        {
          text: "Fecha de finalización",
          value: "tPurchaseOrderStatusDateView",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Venc. de factura",
          value: "iBillDays",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
        {
          text: "Pago",
          value: "sPaid",
          cellClass: "mon f-s14 color000000 text-center",
          class: "f-s14 text-center ",
        },
        {
          text: "Acciones",
          value: "actions",
          align: "center",
          cellClass: "mon f-s14 color000000",
          class: "f-s14",
        },
      ],
      actions: {
        labels: {
          textEdit: "Editar",
          textActivate: "Activar",
          textDeactivate: "Desactivar",
          textRecoveryPassword: "Recuperar contraseña",
          textDelete: "Eliminar",
          textDetails: "Detalle",
        },
      },
      status: {
        textActivate: "Activado",
        textDeactivate: "Desactivado",
        doesNotApply: "No aplica",
        day: " Día",
        days: " Días",
      },
    },
    pagination: {
      paginationRowPerPage: "Filas por página",
      of: "de",
    },
    dialogDelete: {
      textTitle: "Compradores",
    },
    dialogEdit: {
      title: "Editar comprador",
      description:
        "Edite la información a mostrar del subadministrador en el listado.",
      placeholders: {
        latitude: "Capture la latitud",
        longitude: "Capture la longitud",
        blueBook: "Capture el id del blue book",
        description: "Capture la descripción",
        email: "Capture el correo eletrónico",
        address: "Capture la dirección",
        city: "Capture la ciudad",
        zipCode: "Capture el código postal",
        name: "Capture el nombre",
        license: "Capture el número de lincencia de PACA",
        phoneNumber: "Capture el número telefónico",
        state: "Seleccione el estado",
        taxPayerId: "Capture el tax payer id",
        country: "Seleccione el país",
      },
      buttons: {
        edit: "Editar",
        cancel: "Cancelar",
      },
      labels: {
        latitude: "Latitud",
        longitude: "Longitud",
        blueBook: "Id blue book",
        description: "Descripción",
        email: "Correo electrónico",
        address: "Dirección",
        city: "Ciudad",
        zipCode: "Código postal",
        name: "Nombre",
        license: "Lincencia PACA",
        phoneNumber: "Número telefónico",
        state: "Estado",
        taxPayerId: "Id tax payer",
        country: "País",
      },
    },
    tooltip: {
      search: "Buscar",
    },
    tabsItems: {
      inProcess: "En proceso",
      finished: "Finalizados",
    },
    tabsItemsFreight: {
      inProcess: "Fletes",
      interrupted: "PO's sin asignar",
    },
    detailOrder: {
      estimateddeliverydate: "Fecha estimada de entrega",
      pleaseEnterDate: "Ingrese una fecha...",
      next: "Siguiente",
      update: "Actualizar",
      estimateddeliverydateTitle: "Cambio de fecha de entrega estimada",
      estimateddeliverydateDescritpion:
        "Esta acción cambiará la fecha estimada de entrega.",
      estimateddeliverydateQuestion: "¿Desea continuar con esta acción?",
      estimateddeliverydateCancel: "Cancelar",
      estimateddeliverydateChange: "Actualizar",
      toRetrun: "Regresar",
      creation: "Creación",
      ending: "Finalización",
      status: "Estado",
      toUpdate: "Actualizar",
      payment: "Pago",
      billExpiration: "Vencimiento de factura",
      orderTotal: "Total de la orden",
      vendorAndCustomerSection: {
        vendor: "Proveedor",
        customer: "Comprador",
        originWarehouse: "Almacén origen",
        destinationWarehouse: "Almacén destino",
        warehouseManagerName: "Nombre del gerente de almacén",
        phone: "Teléfono",
        email: "Correo electrónico",
      },
      vendorAndCustomerFilesSection: {
        vendorFile: "Documentos generales del proveedor",
        customerFile: "Documentos generales del comprador",
        proofOfPayment: "Comprobante de pago",
        invoice: "Factura",
        downloadFile: "Descargar archivo",
        addFile: "Añadir archivo",
        thereIsNotDocumentToDownload: "No hay documento para descargar",
        delete: {
          title: "Eliminar archivo",
          description:
            "¿Estás seguro de eliminar el archivo? Al realizar esta acción no podrá revertirse",
          note: "",
          chkLabel: "",
        },
      },
      requiredFilesSection: {
        requiredFiles: "Documentos obligatorios",
        headers: { name: "Nombre", file: "Archivo", actions: "Acciones" },
        headersTableCard: [
          {
            text: "Nombre",
            value: "sPurchaseOrderFileTypeName",
            cellClass: "color283C4D f-s14 mon-Medium l-opacity1 l-spacing0",
            class: "mon-bold f-s14",
          },
          {
            text: "Archivo",
            value: "file",
            cellClass: "color283C4D f-s14 mon-Medium l-opacity1 l-spacing0",
            class: "mon-bold f-s14",
          },
          {
            text: "Acciones",
            value: "actions",
            align: "center",
            class: "mon-bold f-s14",
          },
        ],
        reliabilityCertificate: "Certificado de fiabilidad",
        customsProof: "Comprobante de aduana",
        addFile: "Añadir archvio",
        delete: {
          title: "Eliminar archivo",
          description:
            "¿Estás seguro de eliminar el archivo? Al realizar esta acción no podrá revertirse",
          note: "",
          chkLabel: "",
        },
      },
      historyFilesSection: {
        historyFiles: "Historial de documentos",
        headers: {
          name: "Nombre",
          date: "Fecha",
          status: "Estado",
          actions: "Acciones",
        },
        headersTableCard: [
          {
            text: "Nombre",
            value: "sPurchaseOrderFileTypeName",
            cellClass: "mon f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Fecha",
            value: "tCreatedAtView",
            cellClass: "mon f-s14 color000000",
            class: "f-s14",
          },
          {
            text: "Estado",
            value: "sPurchaseOrderStatusName",
            cellClass: "mon f-s14 color000000",
            class: "f-s14",
          },
          {
            text: "Acciones",
            value: "actions",
            align: "center",
            class: "mon-bold f-s14",
          },
        ],
        noFilesYet: "No hay archivos aún",
      },
      purchaseOrdersSection: {
        purchaseOrdersAndCommissions: "Órdenes de compra y Comisiones",
        purchaseOrders: "Órdenes de compra",
        unitsRequested: "Unidades solicitadas",
        finalunits: "Unidades finales",
        pricePerUnitRequested: "Precio por unidad solicitada",
        finalPrice: "Precio unitario final (proveedor)",
        finalPriceCustomer: "Precio unitario final (comprador)",
        finalSubtotal: "Subtotal final",
        baseAmount: "Monto base",
        commissions: "Desglose de costo",
        fixedRate: "Tarifa fija",
        finalPrice: "Precio unitario final (proveedor)",
        finalPriceCustomer: "Precio unitario final (comprador)",
        freightCostMexico: "Costo de flete(México)",
        customsCostUS: "Costo aduana(EE.UU)",
        freightCostUS: "Costo flete(EE.UU)",
        inOut: "In / Out",
        customs: "Aduana",
        subTotal: "Subtotal",
        sReverse:"Revertir",
        sCalculate: "Calcular",
        saveChange: "Guardar cambios",
        addProduct: "Añadir artículos",
        infromationbLock:
          "Al seleccionar la fila, se tomará en cuenta la orden para su cambio",
        infromationbLockComissions:
          "Al seleccionar la fila, se tomará en cuenta la comisión para su cambio",
        subtotalOfOrders: "Subtotal de pedidos",
        subtotalOfComissions: "Subtotal de comisiones",
        name: "Nombre",
        warehouseHandling: "Manejo en almacén",
        noOfPallets: "Num pallets",
        pricePerPallet: "Precio por pallet",
        headersTableCard: [
          {
            text: "Nombre",
            value: "sProductName",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Seleccionar la fila",
            value: "bLock",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Unidades solicitadas",
            value: "dOrderedQuantity",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Unidades finales",
            value: "dDeliveredQuantity",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 classHiddenOrders",
            align: "center",
          },
          {
            text: "Precio unitario de venta (proveedor)",
            value: "dOrderedUnitPrice",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Precio final por unidad (proveedor)",
            value: "dDeliveredUnitPrice",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 classHiddenOrders",
            align: "center",
          },
          {
            text: "Precio unitario de compra (comprador)",
            value: "dOrderedUnitPriceClient",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Precio final por unidad (comprador)",
            value: "dDeliveredUnitPriceClient",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 classHiddenOrders",
            align: "center",
          },
          {
            text: "Subtotal monto de venta (proveedor)",
            value: "dFinalPrice",
            cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
          {
            text: "Subtotal monto de venta (comprador)",
            value: "dFinalPriceClient",
            cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
        ],
        headersTableWarehouseCard: [
          {
            text: "Nombre",
            value: "name",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Num pallets",
            value: "dTotalPallets",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Precio por pallet",
            value: "dInOutRateValue",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Subtotal",
            value: "dTotalPrice",
            cellClass:
              "mon-bold f-s12 color000000 text-center background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
        ],
        headersTableFeesCard: [
          {
            text: "Nombre",
            value: "sFeeName",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Seleccionar la fila",
            value: "bLock",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Monto base",
            value: "dBaseAmount",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Comisión",
            value: "dPercentage",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Tarifa fija",
            value: "dAmount",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Subtotal de comisiones",
            value: "dTotal",
            cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
        ],
        headersTableFeesCardWithoutSelected: [
          {
            text: "Nombre",
            value: "sFeeName",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
          },
          {
            text: "Monto base",
            value: "dBaseAmount",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14",
            align: "center",
          },
          {
            text: "Comisión",
            value: "dPercentage",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Tarifa fija",
            value: "dAmount",
            cellClass: "mon-regular f-s12 color000000",
            class: "f-s14 ",
            align: "center",
          },
          {
            text: "Subtotal de comisiones",
            value: "dTotal",
            cellClass: "mon-bold f-s12 color000000 text-end background3194FF",
            class: "f-s14 background3194FF",
            align: "center",
          },
        ],
        noFilesYet: "No hay órdenes aún",
      },
      warehouseSection: {
        warehouse: "Almacén",
        shippingWarehouse: "Almacén de envío",
        deliveryWarehouse: "Almacén de entrega",
      },
      paymentMethodSection: {
        paymentMethod: "Método de pago",
        payment: "Pago",
        titleCharged: "Pedido de venta",
        titlepaid: "Orden de compra",
        markAsPaid: "Marcar pagado",
        markCharged: "Marcar cobrado",
        paid: "Pagado",
        charged: "Cobrado",
        titleDialogPaid: "Cambio de estado",
        detailDialogPaid:
          "Marcar cobrado un pedido de venta es una acción irreversible.",
        titleDialogCharged: "Cambio de estado",
        detailDialogCharged:
          "Marcar como pagado una orden de compra es una acción irreversible.",
        questionDialog: "¿Desea continuar con esta acción?",
        cancel: "Cancelar",
        pay: "Confirmar",
      },
      trackingSection: {
        NoPictures: "No hay imágenes",
        description: "Descripción",
        tracking: "Seguimiento",
        orderReceived: "Pedido recibido",
        inPreparation: "En preparación",
        inTransit: "En tránsito",
        inTheProcessOfBorderCrossing: "En proceso de cruce fronterizo",
        successfulCrossover: "Cruce exitoso",
        borderWarehouse: "Almacén frontera",
        acceptedAtBorderWarehouse: "Aceptado en almacén frontera",
        transferred: "Transferido",
        onDeliveryRoute: "En ruta de entrega",
        delivered: "Entregado",
        finished: "Finalizado",
        mouseOverImageToZoom:
          "Pasa el mouse encima de la imagen para aplicar zoom",
      },
    },

    dialogAddProduct: {
      sSalesOrderExchangeRate: "TIPO DE CAMBIO DE ORDEN DE COMPRA",
      sPer:"por",

      titleAdd: "Añadir artículo al pedido",
      selectCat: "Seleccionar categoría",
      selectSub: "Seleccionar subcategoría",
      selectProduct: "Seleccionar producto",
      selectCant: "Seleccionar cantidad",
      selectCat1: "Seleccionar una categoría...",
      selectSub1: "Seleccionar una subcategoría...",
      selectProduct1: "Seleccionar un producto...",
      selectCant1: "33",

      sWeigthPer: "Peso por",
      sAproxPiecesPer: "Piezas por",
      sNumberOf: "Número de",
      sPerPallet: "por pallet",
      sStorageTemperature: "Temperatura en almacen",
      sTransportationTemperature: "Temperatura en traslado",
      sPricePerPallet: "Precio por pallet",
      sAddSalesOrder: "Añadir a la orden de compra",
      sInventory: "Inventario",
      sQuantity: "Cantidad",
      sMinimumSellingAmount: "Cantidad mínima de venta",
      sAvailablePalletsOf: "tarimas disponibles de",
      sTotal: "totales",

      buttonsActions: {
        return: "Regresar",
        addProductbtn: "Añadir artículo",
      },
    },
    dialogEditFiles: {
      title: "Editar archivo del historial",
      nameFile: "Nombre del Archivo",
      dateFile: "Fecha",
      status: "Estado",
      file: "Documento",
      buttonsActions: {
        cancel: "Cancelar",
        saveChange: "Guardar cambios",
      },
    },
    dialogUpdateStatus: {
      statusUpdate: "Actualización de estado",
      availableStates: "Estados disponibles",
      selectAState: "Seleccionar un estado…",
      comments: "Comentarios",
      clickOrDragToAddImage: "Clic o arrastrar para añadir imagen",
      textResponseLimitImages: "Excede el número de imágenes permitidas",
      youNeedToAddTheRequiredFiles:
        "Es necesario agregar los documentos obligatorios",
      buttonsActions: {
        return: "Regresar",
        updateStatus: "Actualizar estado",
      },
    },
    searchbar: {
      search: "Ingrese folio de pedido...",
      startDate: "Fecha Inicio",
      finalDate: "Fecha final",
    },
    deleteProducts: {
      title: "Eliminar artículo",
      description: "¿Estás seguro de eliminar el artículo?",
      note: "",
      chkLabel: "",
      cancel: "Cancelar",
      delete: "Eliminar",
    },
    informationNotYetRegistered: "Información no registrada aún",
  },
};

export default texts;
